import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import logo from '../img/logo-white.png';
import GiftSalesPage from '../components/GiftSalesPage';
import axios from 'axios';

function HideHadiah() {
  const { id } = useParams();
  const [activated, setActivated] = useState(false);

  useEffect(() => {
    function giftStatus() {
      axios.get(`https://api.jemputlah.com/api/clients/${id}`)
        .then(response => {
          const hide_gift = response.data.hide_gift;
          if(hide_gift){
            setActivated(JSON.parse(hide_gift));
          } else {
            setActivated(false);
          }
        })
        .catch(error => {
            console.error('Error fetching data:', error);
        });
    }
    
    giftStatus();
  }, [id]);

  return (
    <div>
      <Helmet>
        <title>Hide Butang Hadiah | Jemputlah.com</title>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      { activated ?
        (
          <div>
            <header className="w-full bg-red-400 shadow-md py-4 z-10">
              <div className="flex items-center justify-center px-6">
                <a href="/"><img src={logo} alt="Logo" className='h-8' /></a>
              </div>
            </header>
            <div className="md:p-24 flex items-center justify-center">
              <div className="bg-white px-8 py-12 rounded-md md:shadow-lg max-w-md w-full text-center">
                <h1 className="text-2xl font-bold mb-4">Hide Butang Hadiah</h1>
                <div className='space-y-4 mb-6'>
                  <p className="text-gray-700">
                    Butang Hadiah telah di keluarkan dari Kad Kahwin Digital anda.                        
                  </p>
                </div>
              </div>
            </div>
          </div>
          ) : (
          <div>
            <GiftSalesPage />
          </div>
        )
      }
    </div>
  );
}

export default HideHadiah;
