import theme1 from '../img/theme1.png';
import theme2 from '../img/theme2.png';
import theme3 from '../img/theme3.png';
import theme4 from '../img/theme4.png';
import theme5 from '../img/theme5.png';
import theme6 from '../img/theme6.png';
import theme7 from '../img/theme7.png';
import theme8 from '../img/theme8.png';
import theme9 from '../img/theme9.png';
import theme10 from '../img/theme10.png';
import theme11 from '../img/theme11.png';
import theme12 from '../img/theme12.png';
import theme13 from '../img/theme13.png';
import theme14 from '../img/theme14.png';
import theme15 from '../img/theme15.png';
import theme16 from '../img/theme16.png';
import theme17 from '../img/theme17.png';
import theme18 from '../img/theme18.png';
import theme19 from '../img/theme19.png';


export const themes = [
  {
      id: 1,
      title: 'Tema 1',
      image: theme1,
  },
  {
      id: 2,
      title: 'Tema 2',
      image: theme2,
  },
  {
      id: 3,
      title: 'Tema 3',
      image: theme3,
  },
  {
      id: 4,
      title: 'Tema 4',
      image: theme4,
  },
  {
      id: 5,
      title: 'Tema 5',
      image: theme5,
  },
  {
      id: 6,
      title: 'Tema 6',
      image: theme6,
  },
  {
      id: 7,
      title: 'Tema 7',
      image: theme7,
  },
  {
      id: 8,
      title: 'Tema 8',
      image: theme8,
  },
  {
      id: 9,
      title: 'Tema 9',
      image: theme9,
  },
  {
      id: 10,
      title: 'Tema 10',
      image: theme10,
  },
  {
      id: 11,
      title: 'Tema 11',
      image: theme11,
  },
  {
      id: 12,
      title: 'Tema 12',
      image: theme12,
  },
  {
      id: 13,
      title: 'Tema 13',
      image: theme13,
  },
  {
      id: 14,
      title: 'Tema 14',
      image: theme14,
  },
  {
      id: 15,
      title: 'Tema 15',
      image: theme15,
  },
  {
      id: 16,
      title: 'Tema 16',
      image: theme16,
  },
  {
      id: 17,
      title: 'Tema 17',
      image: theme17,
  },
  {
      id: 18,
      title: 'Tema 18',
      image: theme18,
  },
  {
      id: 19,
      title: 'Tema 19',
      image: theme19,
  },
];
