import React from "react";
import axios from "axios";

const UpdateRsvpButton = () => {
  const handleUpdateRsvp = async () => {
    try {
      await axios.post("https://api.jemputlah.com/api/update-rsvp");
      alert("RSVPs updated successfully");
    } catch (error) {
      console.error("Error updating RSVPs:", error);
      alert("Failed to update RSVPs");
    }
  };

  return <button onClick={handleUpdateRsvp}>Update RSVP</button>;
};

export default UpdateRsvpButton;
