import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import Themes from '../Themes';

function PreviewInviteMessage() {
  const { theme } = useParams();
  const Components = Themes[theme];

  // const localStorageParentName = localStorage.getItem('ParentName');
  // const localStorageMessage = localStorage.getItem('Message');
  // const localStorageGroomFullName = localStorage.getItem('GroomFullName');
  // const localStorageBrideFullName = localStorage.getItem('BrideFullName');

  const parentName = "Abdul Razak bin Abdullah\n&\nAminah binti Ali";
  const message = "Dengan penuh kesyukuran, kami menjemput Dato' | Datin | Tuan | Puan | Encik | Cik seisi keluarga hadir ke majlis perkahwinan anakanda kami";
  const GroomFullName = "Muhammad Adam bin Abdul Razak";
  const BrideFullName = "Siti Hawa binti Faizal";
  
  // const [parentName, setParentName] = useState(localStorageParentName || "Abdul Razak bin Abdullah\n&\nAminah binti Ali");
  // const [message, setMessage] = useState(localStorageMessage || "Dengan penuh kesyukuran, kami menjemput Dato' | Datin | Tuan | Puan | Encik | Cik seisi keluarga hadir ke majlis perkahwinan anakanda kami");
  // const [GroomFullName, setGroomFullName] = useState(localStorageGroomFullName || "Muhammad Adam bin Abdul Razak");
  // const [BrideFullName, setBrideFullName] = useState(localStorageBrideFullName || "Siti Hawa binti Faizal");
  
  // const selectedH2 = localStorage.getItem('H2');
  // const selectedfontP = localStorage.getItem('fontP');
  const H2 = Components.H2;
  const fontP = Components.fontP;
  
  // const H2 = (selectedH2 || Components.H2);
  // const fontP = (selectedfontP || Components.fontP);

  return (
    <div className="text-center pt-20 pb-10 md:w-3/5 w-4/5 mx-auto">
      <h1 className={`text-l mb-8 ${fontP}`}>Walimatulurus</h1>
      <div className={`text-3xl ${H2}`}>
        {parentName.split('\n').map((text, index) => (
          <p key={index}>{text}</p>
        ))}
      </div>
      <div className={`text-l my-8 ${fontP}`}>
        {message.split('\n').map((text, index) => (
          <p key={index}>{text}</p>
        ))}
      </div>

      <p className={`text-3xl ${H2}`}>{GroomFullName}</p>

      <p className={`text-3xl ${H2}`}>&</p>

      <p className={`text-3xl ${H2}`}>{BrideFullName}</p>
      
    </div>
  );
}

export default PreviewInviteMessage;
