import React from 'react';
import RSVPForm from './rsvp/RSVPForm';

function LiveRSVP() {

  return (
    <div>
      <RSVPForm />
    </div>
  );
}

export default LiveRSVP;
