import React, { useState, useEffect } from 'react';
import Themes from '../Themes';

function DemoCountdown() {
  const localStorageTheme = localStorage.getItem('theme');
  const localStorageDoa = localStorage.getItem('doa');
  const localStorageWeddingDate = localStorage.getItem('wedding_date');
  const selectedfontP = localStorage.getItem('fontP');

  const theme = (localStorageTheme || "theme6");
  const Components = Themes[theme];

  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);
  
  const targetDate = new Date(localStorageWeddingDate || tomorrow).getTime();

  // Calculate the remaining time with UTC+8 offset
  const calculateTimeLeft = () => {
    const now = new Date().getTime() + 8 * 60 * 60 * 1000; // UTC+8 offset
    const difference = targetDate - now;

    if (difference <= 0) {
      return {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
      };
    }

    const days = Math.floor(difference / (1000 * 60 * 60 * 24));
    const hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((difference % (1000 * 60)) / 1000);

    return {
      days,
      hours,
      minutes,
      seconds,
    };
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());
  const [doa, setDoa] = useState(localStorageDoa || "Ya Allah Ya Rahman Ya Rahim.\nBerkatilah majlis perkahwinan ini.\nLimpahkanlah baraqah dan rahmatMu kepada kedua mempelai ini. Kurniakanlah mereka kelak zuriat yang soleh dan solehah. Kekalkanlah jodoh mereka hingga ke jannah.");

  useEffect(() => {
    const countdownInterval = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => {
      clearInterval(countdownInterval);
    };
  }, []);

  const fontP = (selectedfontP || Components.fontP);

  return (
    <div className={`pt-10 pb-20 ${fontP}`}>
      <div className='text-center pt-10 pb-10 md:w-3/5 w-4/5 mx-auto'>
        {doa.split('\n').map((text, index) => (
          <p className="text-l" key={index}>{text}</p>
        ))}

        {/* <p className="text-l">Ya Allah Ya Rahman Ya Rahim.</p>
        <p className="text-l">Berkatilah majlis perkahwinan ini.</p>
        <p className="text-l">Limpahkanlah baraqah dan rahmatMu kepada kedua mempelai ini. Kurniakanlah mereka kelak zuriat yang soleh dan solehah. Kekalkanlah jodoh mereka hingga ke jannah.</p> */}
      </div>
      <p className={`text-l uppercase text-center mb-8 ${fontP}`}>Menghitung Hari</p>
      <div className={`flex justify-center md:space-x-4 space-x-2 ${fontP}`}>
        <div className={Components.cd1}>
          <div className="text-3xl md:text-5xl font-bold">{timeLeft.days}</div>
          <div className="text-xl">Hari</div>
        </div>
        <div className={Components.cd2}>
          <div className="text-3xl md:text-5xl font-bold">{timeLeft.hours}</div>
          <div className="text-xl">Jam</div>
        </div>
        <div className={Components.cd3}>
          <div className="text-3xl md:text-5xl font-bold">{timeLeft.minutes}</div>
          <div className="text-xl">Minit</div>
        </div>
        <div className={Components.cd4}>
          <div className="text-3xl md:text-5xl font-bold">{timeLeft.seconds}</div>
          <div className="text-xl">Saat</div>
        </div>
      </div>
    </div>
  );
}

export default DemoCountdown;
