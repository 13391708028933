import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FaSpinner } from 'react-icons/fa';

function CreatePublish() {
  const [loading, setLoading] = useState(true);

  const localStorageTheme = localStorage.getItem('theme');
  const localStorageGroomName = localStorage.getItem('groom_name');
  const localStorageBrideName = localStorage.getItem('bride_name');

  const localStorageParentName = localStorage.getItem('parent_name');
  const localStorageMessage = localStorage.getItem('message');
  const localStorageGroomFullName = localStorage.getItem('groom_fullname');
  const localStorageBrideFullName = localStorage.getItem('bride_fullname');

  const localStorageWeddingDate = localStorage.getItem('wedding_date');
  const localStorageLocation = localStorage.getItem('location');
  const localStorageStartTime = localStorage.getItem('start_time');
  const localStorageEndTime = localStorage.getItem('end_time');

  const localStorageTentative = localStorage.getItem('tentative');

  const localStorageContacts = localStorage.getItem('contacts');
  const localStorageDirections = localStorage.getItem('directions');

  const localStorageH2 = localStorage.getItem('H2');
  const localStorageH2size = localStorage.getItem('H2size');
  const localStorageFontP = localStorage.getItem('fontP');
    
  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);

  const defaultTentative = [
    { "title": "Kehadiran tetamu", "displaytime": "11:30 AM", "time":"11:30"},
    { "title": "Ketibaan Pengantin", "displaytime": "12:30 PM", "time":"12:30"},
    { "title": "Makan Beradab", "displaytime": "1:30 PM", "time":"13:30"},
    { "title": "Majlis Berakhir", "displaytime": "4:00 PM", "time":"16:00"},
  ];

  const demoContacts = [
    {
      "name": "Adam",
      "phoneNumber": "0123456789",
      "contactMethod": "both"
    },
  ];

  const demoDirections = [
    {
      "locationName": "Corus Hotel",
      "wazeUrl": "https://ul.waze.com/ul?preview_venue_id=66650144.666566972.474270&navigate=yes&utm_campaign=default&utm_source=waze_website&utm_medium=lm_share_location",
      "googleMapsUrl": "https://maps.app.goo.gl/FxK74Gyh4wxyP1Nx9"
    }
  ];

  const theme = (localStorageTheme || "theme3");
  const groom_name = (localStorageGroomName || "Adam");
  const bride_name = (localStorageBrideName || "Hawa");
  const wedding_date = (localStorageWeddingDate ? new Date(localStorageWeddingDate) : tomorrow);

  const parent_name = (localStorageParentName || "Abdul Razak bin Abdullah\n&\nAminah binti Ali");
  const message = (localStorageMessage || "Dengan penuh kesyukuran, kami menjemput Dato' | Datin | Tuan | Puan | Encik | Cik seisi keluarga hadir ke majlis perkahwinan anakanda kami");
  const groom_fullname = (localStorageGroomFullName || "Muhammad Adam bin Abdul Razak");
  const bride_fullname = (localStorageBrideFullName || "Siti Hawa binti Faizal");

  const location = (localStorageLocation || "Corus Hotel, Kuala Lumpur");
  const start_time = (localStorageStartTime || "11:30");
  const end_time = (localStorageEndTime || "17:00");

  const tentative = (localStorageTentative || defaultTentative);

  const contacts = (localStorageContacts || demoContacts);
  const directions = (localStorageDirections || demoDirections);

  const H2 = localStorageH2;
  const H2size = localStorageH2size;
  const fontP = localStorageFontP;
  const status = "verified";

  const [id, setId] = useState(null);
  
  useEffect(() => { 
    const apiEndpoint = 'https://api.jemputlah.com/api/invitation-cards'; 

    const dataToSave = {
      theme,
      groom_name,
      bride_name,
      wedding_date,
      parent_name,
      message,
      groom_fullname,
      bride_fullname,
      location,
      start_time,
      end_time,
      tentative,
      contacts,
      directions,
      H2,
      fontP,
      H2size,
      status,
    };

    axios.post(apiEndpoint, dataToSave)
      .then(response => {
        console.log(response.data);
        setId(response.data);
        localStorage.clear();
        setLoading(false);
      })
      .catch(error => {
        console.error('Error saving data:', error);
        setLoading(false);
      });
  }, []);

  const url = `https://jemputlah.com/kad/${id}`;
  const event = `${groom_name}x${bride_name}`;

  const handleShare = () => {

    if (navigator.share) {
      navigator.share({
        title: 'Walimatulurus',
        text: `Jemputan Walimatulurus ${event}`,
        url: url,
      })
        .then(() => console.log('Successful share'))
        .catch((error) => console.error('Error sharing:', error));
    } else {
      alert(`Share this link: ${url}`);
    }
  };

  return (
    <div>
      {loading ? ( 
        <div className="mt-12 flex items-center justify-center">
          <FaSpinner className="text-4xl text-purple-600 animate-spin" />
        </div>
      ) : (
        <div className="bg-white px-8 py-12 rounded-md md:shadow-lg max-w-md w-full text-center">
          <h1 className="text-2xl font-bold mb-4">Tahniah! Kad kahwin anda sudah aktif.</h1>
          <p className="text-gray-700 mb-4">
          Selamat Pengantin Baru. Semoga dipermudahkan segala urusan. Share link dibawah kepada semua jemputan anda. 
          </p>
          <input
            type="text"
            value={url}
            readOnly
            // ref={inputRef}
            className="border px-5 py-3 mb-2 w-full"
          />
          <div className='flex flex-row items-center justify-center space-x-2'>
            <button
              onClick={handleShare}
              className="bg-red-400 hover:bg-red-500 text-white px-5 py-3 rounded w-full"
            >
              Share
            </button>
            <a
              href={url}
              target="_blank"
              className="bg-white text-center text-red-400 hover:bg-red-400 hover:text-white px-5 py-3 rounded w-full border-solid border-2 border-red-400"
            >
              Lihat Kad
            </a>
          </div>
          <a
            href="https://blog.jemputlah.com"
            target="_blank"
            className="my-6 font-bold text-center bg-yellow-500 hover:bg-yellow-600 text-black px-5 py-5 rounded w-full block animate-bounce"
          >
            Idea Doorgift Eksklusif Bajet
          </a>
        </div>
      )}
    </div>
      
  );
}

export default CreatePublish;