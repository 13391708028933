import React from 'react';
import { motion, useScroll, useTransform } from 'framer-motion'; // Import Framer Motion
import bg1 from './img/bg1.jpg';
import ft1 from './img/ft1.png';
import fb1 from './img/fb1.png';

function Theme1() {
  const weddingDate = "2024-12-15";
  const weddingDateSet = new Date(weddingDate);
  const weddingDay = getDayFromDate(weddingDateSet);

  const formattedDate = weddingDateSet.toLocaleDateString('ms-MY', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });

  // Function to calculate the day from a given date
  function getDayFromDate(date) {
    const days = ['Ahad', 'Isnin', 'Selasa', 'Rabu', 'Khamis', "Jumaat", 'Sabtu'];
    const dayIndex = date.getDay();
    return days[dayIndex];
  }

  const heroStyle = {
    backgroundImage: `url(${bg1})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  };

  // Use the scroll progress to trigger animations
  const { scrollYProgress } = useScroll();
  
  // Transform scroll progress to scaling and translation values
  const scaleUp = useTransform(scrollYProgress, [0, 1], [1, 2]);
  const slideOut = useTransform(scrollYProgress, [0, 1], [0, -200]);

  return (
    <div style={heroStyle}>
      <div className='h-screen sticky flex flex-col justify-center items-center'>
        <motion.div 
          className="text-center p-20 md:w-1/2 sm:w-2/3 w-full text-black space-y-8"
          initial={{ opacity: 0, y: 0 }} 
          animate={{ opacity: 1, y: 0 }} 
          transition={{ duration: 1, delay: 0.5 }}
        >
          <p className="text-l mb-8 font-normal uppercase">Walimatulurus</p>
          <div>
            <motion.h2 
              className="text-5xl font-great-vibes capitalize"
              initial={{ scale: 0 }} 
              animate={{ scale: 1 }} 
              transition={{ duration: 0.8, delay: 0.7 }}
            >
              Adam
            </motion.h2>
            <p className="text-3xl font-great-vibes capitalize">&</p>
            <motion.h2 
              className="text-5xl font-great-vibes capitalize"
              initial={{ scale: 0 }} 
              animate={{ scale: 1 }} 
              transition={{ duration: 0.8, delay: 1 }}
            >
              Hawa
            </motion.h2>
          </div>
          <div className="mt-8">
            <p className="text-l font-normal mb-2">{weddingDay}, {formattedDate}</p>
          </div>
        </motion.div>

        <motion.img
          src={ft1}
          className="absolute top-0 right-0 md:w-1/3 sm:w-1/2 w-2/3"
          style={{ scale: scaleUp, y: slideOut }}
        />
        <motion.img
          src={fb1}
          className="absolute bottom-0 left-0 md:w-1/3 sm:w-1/2 w-2/3"
          style={{ scale: scaleUp, y: slideOut }}
        />
      </div>

      <motion.div className="text-center pt-20 pb-10 md:w-3/5 w-4/5 mx-auto">
        <h1 className={`text-l mb-8 font-normal capitalize`}>Walimatulurus</h1>
        <div className={`text-3xl font-great-vibes`}>
            <p>Ismail bin Hitam</p>
            <p>&</p>
            <p>Rokiah binti Rahmat</p>
        </div>
        <div className={`text-l my-8 font-normal`}>
            <p>
              Dengan penuh kesyukuran, kami menjemput Dato' | Datin | Tuan | Puan | Encik | Cik seisi keluarga hadir ke majlis perkahwinan anakanda kami
            </p>
        </div>

        <div className={`text-3xl font-great-vibes`}>
            <p>Muhammad Adam bin Ismail</p>
        </div>

        <p className={`text-3xl font-great-vibes`}>&</p>

        <div className={`text-3xl font-great-vibes`}>
          <p>Nurhawa Rizwana binti Karim</p>
        </div>
        
      </motion.div>
    </div>
  );
}

export default Theme1;
