import axios from 'axios';

const sendEmail = async (to, templateId, params) => {
  try {
    const response = await axios.post(
      'https://api.brevo.com/v3/smtp/email',
      {
        to: [{ email: to }],
        templateId: templateId,
        params: params,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          'api-key': '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',
        },
      }
    );

    console.log('Email sent successfully:', response.data);

  } catch (error) {
    console.error('Error sending email:', error.response.data);
  }
};

export default sendEmail;
