import React from 'react';
import logo from '../../img/logo-red.png';

function DemoNavBar() {
  // const logo = 'font-playfair-display';
  return (
    // <header className="fixed top-0 left-0 w-full bg-red-400 shadow-md py-4 z-10">
    <header className="fixed top-0 left-0 w-full bg-white bg-opacity-80 shadow-md py-4 z-10">
      <div className="flex items-center justify-center px-6">
        <a href="/"><img src={logo} alt="Logo" className='h-8' /></a>
        {/* <a className={`text-xl ${logo} text-black font-bold`} href="/">Jemputlah.com</a> */}
      </div>
    </header>
  );
}

export default DemoNavBar;