import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';

function LiveUcapan({ fontP }) {
  const { id } = useParams();
  const [ucapan, setUcapan] = useState([]);
  const [ucapanExist, setUcapanExist] = useState(false);

  useEffect(() => {
    axios.get(`https://api.jemputlah.com/api/rsvp/${id}`)
      .then(response => {
        const rsvps = response.data;
        setUcapan(rsvps);
        const ucapanFiltered = rsvps.filter(item => item.ucapan !== null);
        if (ucapanFiltered.length > 0) {
          setUcapanExist(true);
        }
      })
      .catch(error => {
          console.error('Error fetching data:', error);
      });
  }, [id]);

  // Sorting the ucapanFiltered array by created_at in descending order
  const sortedUcapan = ucapan.filter(item => item.ucapan !== null).sort((a, b) => new Date(b.created_at) - new Date(a.created_at));

  return (
    <div className={`py-5 ${fontP} text-center`}>
      {ucapanExist && (
        <div>
          <h2 className="text-lg uppercase mb-4">Ucapan</h2>
          <div className='md:w-3/5 w-4/5 mx-auto overflow-y-auto max-h-96'>
            {sortedUcapan.map((item, index) => (
              <div key={index} className="border-b py-8">
                <p>{item.ucapan}</p>
                <p className="text-lg font-semibold mt-4">~ {item.name} ~</p>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

export default LiveUcapan;
