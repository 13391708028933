import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import LogMasuk from '../components/dashboard/LogMasuk';
import Auth from '../components/dashboard/Auth';
import { FaSpinner } from 'react-icons/fa';
import logo from '../img/logo-white.png';
import logo2 from '../img/logo-red.png';
import UserCards from '../components/UserCards';

function Dashboard() {
  const [login, setLogin] = useState(true);
  const [loading, setLoading] = useState(true);
  
  const logOut = () => {
    localStorage.removeItem('userEmail');
    localStorage.removeItem('token');
    window.location.reload();
  };

  return (
    <div>
      <Helmet>
        <title>Dashboard | Jemputlah.com</title>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <div>
        <Auth setLogin={setLogin} />
        {login ? 
          (
            <div>
              {loading && (<div className="fixed inset-0 h-screen w-full bg-white bg-opacity-80 flex flex-col items-center justify-center space-y-5">
                <FaSpinner className="text-4xl text-red-400 animate-spin" />
                <img src={logo2} alt="Logo" className="h-8 mb-4" />
              </div>)}
              <header className="w-full bg-red-400 shadow-md py-4 z-10">
                <div className="flex items-center justify-center px-6">
                  <a href="/"><img src={logo} alt="Logo" className='h-8' /></a>
                </div>
              </header>
              <div className="md:p-24 flex items-center justify-center">
                <div className="bg-white px-8 py-12 rounded-md md:shadow-lg max-w-lg w-full">
                  <UserCards setLoading={setLoading} />
                  <button 
                  onClick={logOut}
                  className='mt-8 w-full text-white px-5 py-3 rounded-lg bg-red-400 hover:bg-red-500'>
                    Log Keluar
                  </button>
                </div>
              </div>
            </div>
          ) : ( 
            <div>
              <LogMasuk />
            </div>
          )
        }
      </div>
    </div>
  );
}

export default Dashboard;
