import React, { useState } from 'react';
import Themes from '../Themes';

function DemoHero() {
  const localStorageTheme = localStorage.getItem('theme');
  const theme = localStorageTheme || "theme6";

  const selectedH2 = localStorage.getItem('H2');
  const selectedfontP = localStorage.getItem('fontP');
  const selectedH2size = localStorage.getItem('H2size');

  const Components = Themes[theme];

  const localStorageGroomName = localStorage.getItem('groom_name');
  const localStorageWalimatulurus = localStorage.getItem('walimatulurus');
  const localStorageBrideName = localStorage.getItem('bride_name');
  const localStorageWeddingDate = localStorage.getItem('wedding_date');

  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);

  const walimatulurus = localStorageWalimatulurus || "Walimatulurus";
  const groomName = localStorageGroomName || "Adam";
  const brideName = localStorageBrideName || "Hawa";
  const weddingDate = localStorageWeddingDate ? new Date(localStorageWeddingDate) : tomorrow;
  const weddingDay = getDayFromDate(weddingDate);

  const formattedDate = weddingDate.toLocaleDateString('ms-MY', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });

  // Function to calculate the day from a given date
  function getDayFromDate(date) {
    const days = ['Ahad', 'Isnin', 'Selasa', 'Rabu', 'Khamis', "Jumaat", 'Sabtu'];
    const dayIndex = date.getDay();
    localStorage.setItem('Day', days[dayIndex]);
    return days[dayIndex];
  }

  const heroStyle = {
    backgroundImage: `url(${Components.bg})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  };

  const center = {
    backgroundImage: `url(${Components.fc})`,
    backgroundSize: 'contain',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
  };

  const H2 = (selectedH2 || Components.H2);
  const fontP = (selectedfontP || Components.fontP);
  const H2size = (selectedH2size || Components.H2size);
  

  return (
    <div className="h-screen relative flex flex-col justify-center items-center" style={heroStyle}>
      <div className={Components.fcStyle} style={center}>
        <p className={`text-l mb-8 ${fontP} uppercase`}>{walimatulurus}</p>
        <div>
          <h2 className={`${H2size} ${H2} capitalize`}>
            {groomName}
          </h2>
          <p className={`text-3xl ${H2} capitalize`}>&</p>
          <h2 className={`${H2size} ${H2} capitalize`}>
            {brideName}
          </h2>
        </div>
        <div className="mt-8">
          <p className={`text-l ${fontP} mb-2`}>{weddingDay}, {formattedDate}</p>
        </div>
      </div>
      <img
        src={Components.ft}
        
        className={Components.ftStyle}
      />
      <img
        src={Components.fb}
        
        className={Components.fbStyle}
      />
    </div>
  );
}

export default DemoHero;
