import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Themes from '../Themes';

function PreviewTentative() {
  const { theme } = useParams();
  const Components = Themes[theme];

  const heroStyle = {
    backgroundImage: `url(${Components.bg})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  };

  const startTime = "11:30";
  const endTime = "17:00";

  // const localStorageStartTime = localStorage.getItem('StartTime');
  // const localStorageEndTime = localStorage.getItem('EndTime');

  // const [startTime, setStartTime] = useState(localStorageStartTime || "11:30");
  // const [endTime, setEndTime] = useState(localStorageEndTime || "17:00");

  function convertTo12HourFormat(time24) {
    const [hours, minutes] = time24.split(":");
    const period = hours >= 12 ? "pm" : "am";
    const hours12 = hours % 12 || 12; // Convert 0 to 12 for midnight
  
    return `${hours12}:${minutes} ${period}`;
  }

  function addMinutesToTime(timeString, minutesToAdd) {
    const [hours, minutes] = timeString.split(':').map(Number);
  
    // Convert everything to minutes
    const totalMinutes = hours * 60 + minutes;
    const newTotalMinutes = totalMinutes + minutesToAdd;
  
    // Calculate new hours and minutes
    const newHours = Math.floor(newTotalMinutes / 60);
    const newMinutes = newTotalMinutes % 60;
  
    // Format the result
    const formattedTime = `${newHours.toString().padStart(2, '0')}:${newMinutes.toString().padStart(2, '0')}`;
  
    return formattedTime;
  }

  const tetamuHadir = addMinutesToTime(startTime, 30);
  const tibaPengantin = addMinutesToTime(tetamuHadir, 60);
  const makanBeradab = addMinutesToTime(tibaPengantin, 60);

  const tetamuHadirNew = convertTo12HourFormat(tetamuHadir);
  const tibaPengantinNew = convertTo12HourFormat(tibaPengantin);
  const makanBeradabNew = convertTo12HourFormat(makanBeradab);
  const endTimeNew = convertTo12HourFormat(endTime);

  const defaultData = [
    { title: "Kehadiran tetamu", displaytime: tetamuHadirNew, time:tetamuHadir},
    { title: "Ketibaan Pengantin", displaytime: tibaPengantinNew, time:tibaPengantin},
    { title: "Makan Beradab", displaytime: makanBeradabNew, time:makanBeradab},
    { title: "Majlis Berakhir", displaytime: endTimeNew, time:endTime},
  ];

  const aturCaraMajlis = defaultData;

  // const [aturCaraMajlis, setAturCaraMajlis] = useState(() => {
  //   // Try to get the data from localStorage
  //   const storedData = localStorage.getItem('aturCaraMajlis');
  //   return storedData ? JSON.parse(storedData) : defaultData;
  // });

  // Save aturCaraMajlis to localStorage whenever it changes
  // useEffect(() => {
  //   localStorage.setItem('aturCaraMajlis', JSON.stringify(aturCaraMajlis));
  // }, [aturCaraMajlis]);

  const fontP = Components.fontP;
  // const selectedfontP = localStorage.getItem('fontP');
  // const fontP = (selectedfontP || Components.fontP);

  return (
    <div className="min-h-screen relative flex items-center justify-center" style={heroStyle}>
      <div className="text-center z-10 my-20">
        <div className={Components.tStyle}>
        {/* <div className={Components.tStyle}> */}
          <h1 className={`text-l mb-8 uppercase font-bold ${fontP}`}>Atur Cara Majlis</h1>
          {aturCaraMajlis.map((item, index) => (
            <div key={index} className={`my-4 ${fontP}`}>
              <p className="text-l">{item.title}:</p>
              <p className="text-l">{item.displaytime}</p>
            </div>
          ))}
        </div>
      </div>
      <img
        src={Components.ft}
        
        className={Components.ftStyle}
      />
      <img
        src={Components.fb}
        
        className={Components.fbStyle}
      />
    </div>
  );
}

export default PreviewTentative;
