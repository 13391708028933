import React, { useEffect } from 'react';
import axios from 'axios';

const Auth = ({ id, setLogin, setOwnerEmail }) => {
  const email = localStorage.getItem('userEmail');
  const token = localStorage.getItem('token');
  useEffect(() => {
    function fetchAuth() {
      axios.get(`https://api.jemputlah.com/api/clients/${id}`)
      .then(response => {
        const cardEmail = response.data.email;
        setOwnerEmail(cardEmail);

        if(email) {
          axios.post('https://api.jemputlah.com/api/sessions/check', {
            email: email,
            token: token,
          })
            .then(response => {
              if (email.toLowerCase() === cardEmail.toLowerCase()) {
                setLogin(true);
              }
              else {
                setLogin(false);
                console.log('User is signed out');
              }
            })
            .catch(error => {
              console.error('Error fetching data:', error);
              setLogin(false);
            });
        } else {
          // User is signed out
          setLogin(false);
          console.log('User is signed out');
        }
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
    }

    fetchAuth();
  }, [id, setLogin]);

  return null; // or any UI you want to render for this component
};

export default Auth;
