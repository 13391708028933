import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import LogMasuk from '../components/LogMasuk';
import Auth from '../components/Auth';
import { FaSpinner } from 'react-icons/fa';
import logo from '../img/logo-white.png';
import logo2 from '../img/logo-red.png';
import RSVPList from '../components/RSVPList';
import RsvpSalesPage from '../components/RsvpSalesPage';
import axios from 'axios';
import EnableRSVP from '../components/EnableRSVP';

function RSVP() {
  const [login, setLogin] = useState(true);
  const [loading, setLoading] = useState(true);
  const [ownerEmail, setOwnerEmail] = useState('');
  const { id } = useParams();
  const [activated, setActivated] = useState(true);

  useEffect(() => {
    axios.get(`https://api.jemputlah.com/api/clients/${id}`)
      .then(response => {
        const rsvp = response.data.rsvp;
        if(rsvp){
          setActivated(JSON.parse(rsvp));
          setLoading(false);
        } else {
          setActivated(false);
          setLoading(false);
        }
      })
      .catch(error => {
          console.error('Error fetching data:', error);
      });
  }, [id]);
  

  return (
    <div>
      <Helmet>
        <title>RSVP | Jemputlah.com</title>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
        <div> 
          <Auth id={id} setLogin={setLogin} setOwnerEmail={setOwnerEmail} />
          {login ? 
            (
              <div>
                {activated ? (
                  <>
                    {loading && (<div className="fixed inset-0 h-screen w-full bg-white bg-opacity-80 flex flex-col items-center justify-center space-y-5">
                      <FaSpinner className="text-4xl text-red-400 animate-spin" />
                      <img src={logo2} alt="Logo" className="h-8 mb-4" />
                    </div>)}
                    <header className="w-full bg-red-400 shadow-md py-4 z-10">
                      <div className="flex items-center justify-center px-6">
                        <a href="/"><img src={logo} alt="Logo" className='h-8' /></a>
                      </div>
                    </header>
                    <div className="md:p-24 flex items-center justify-center">
                      <div className="bg-white px-8 py-12 rounded-md md:shadow-lg max-w-lg w-full">
                        <EnableRSVP />
                        <RSVPList setLoading={setLoading} />
                      </div>
                    </div>
                  </>
                ) : (
                  <div>
                    <RsvpSalesPage />
                  </div>
                )}
              </div>
            ) : ( 
              <div>
                <LogMasuk ownerEmail={ownerEmail} setLogin={setLogin} />
              </div>
            )
          }
        </div>
    </div>
  );
}

export default RSVP;
