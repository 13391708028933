import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import logo from '../img/logo-white.png';
import Donate from '../components/Donate';
import { Helmet } from 'react-helmet';
import axios from 'axios';
import WhatsAppFAB from '../components/WhatsAppFAB';
import Servis from '../components/Servis';
import { FaWhatsapp } from 'react-icons/fa';

const Success = () => {
  const { id } = useParams();
  const [event, setEvent] = useState('');
  const [walimatulurus, setWalimatulurus] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`https://api.jemputlah.com/api/kad/${id}`);
        const data = response.data;

        setWalimatulurus(data.walimatulurus);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [id]); 

  useEffect(() => {
    const getEvent = async () => {
      axios.get(`https://api.jemputlah.com/api/clients/${id}`)
        .then(response => {
          const event = response.data.event;
          setEvent(event);
        })
        .catch(error => {
            console.error('Error fetching data:', error);
        });
    };

    getEvent();

  }, [id]);
  
  const url = `https://jemputlah.com/kad/${id}`;
  const edit = `/edit/${id}`;
  const rsvp = `/rsvp/${id}`;
  const music = `/music/${id}`;
  const hidehadiah = `/hidehadiah/${id}`;
  const salamkaut = `/salamkaut/${id}`;

  const handleShare = () => {

    if (navigator.share) {
      navigator.share({
        title: walimatulurus,
        text: `Jemputan ${walimatulurus} ${event}`,
        url: url,
      })
        .then(() => console.log('Successful share'))
        .catch((error) => console.error('Error sharing:', error));
    } else {
      alert(`Share this link: ${url}`);
    }
  };

  return (
    <div>
      {/* <WhatsAppFAB />  */}
      <Helmet>
        <title>Tahniah | Jemputlah.com</title>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <header className="w-full bg-red-400 shadow-md py-4 z-10">
        <div className="flex items-center justify-center px-6">
          <a href="/"><img src={logo} alt="Logo" className='h-8' /></a>
        </div>
      </header>
      <div className="md:p-24 flex items-center justify-center">
        <div className="bg-white px-8 py-12 rounded-md md:shadow-lg max-w-md w-full">
          <div className='text-center'>
            <h1 className="text-2xl font-bold mb-4">Tahniah! Kad kahwin anda sudah aktif.</h1>
            <p className="text-gray-700 mb-4">
            Selamat Pengantin Baru. Semoga dipermudahkan segala urusan. Share link dibawah kepada semua jemputan anda. 
            </p>
            <input
              type="text"
              value={url}
              readOnly
              className="border px-5 py-3 mb-2 w-full"
            />
            <button
              onClick={handleShare}
              className="bg-red-400 hover:bg-red-500 text-white px-5 py-3 rounded w-full"
            >
              Share
            </button>
            <div className='flex items-center justify-between space-x-2 mt-2'>
              <a
                href={url}
                target="_blank"
                rel="noreferrer"
                className="bg-white text-center text-red-400 hover:bg-red-400 hover:text-white px-5 py-3 rounded w-full border-solid border-2 border-red-400"
              >
                
                View
              </a>
              <a
                href={edit}
                target="_blank"
                rel="noreferrer"
                className="bg-white text-center text-red-400 hover:bg-red-400 hover:text-white px-5 py-3 rounded w-full border-solid border-2 border-red-400"
              >
                Edit
              </a>
            </div>
            <div className='my-6 space-y-2'>
              <a
                href={rsvp}
                target="_blank"
                rel="noreferrer"
                className="font-bold text-center bg-green-400 hover:bg-green-500 text-white px-5 py-5 rounded w-full block"
              >
                
                RSVP
              </a>
              <a
                href={music}
                target="_blank"
                rel="noreferrer"
                className="font-bold text-center bg-yellow-500 hover:bg-yellow-600 text-black px-5 py-5 rounded w-full block"
              >
                Background Music
              </a>
              <a
                href={salamkaut}
                target="_blank"
                rel="noreferrer"
                className="font-bold text-center bg-purple-500 hover:bg-purple-600 text-white px-5 py-5 rounded w-full block"
              >
                Salam Kaut & Hadiah
              </a>
            </div>
              <a
                href='https://wa.me/60102701320'
                target="_blank"
                rel="noreferrer"
                className="flex items-center justify-center font-bold text-center bg-green-500 hover:bg-green-600 text-white px-5 py-5 rounded w-full"
              >
                <FaWhatsapp className="mr-2" /> Ada soalan?
              </a>
          </div>
          <Donate />
          {/* <Feedback /> */}
          {/* <Poll /> */}
          {/* <Servis /> */}

        </div>
      </div>
    </div>
    
  );
};

export default Success;
