import React, { useState, useEffect } from 'react';
import { FaTimes } from 'react-icons/fa';
import { useParams } from 'react-router-dom';
import { Tooltip } from 'react-tooltip'
import Themes from '../Themes';
import LoadingSpinner from '../LoadingSpinner';
import LocationSearchInput from '../LocationSearchInput';
import CreateLocation from './CreateLocation';
import { Loader } from '@googlemaps/js-api-loader';

function CreateVenue() {
  useEffect(() => {
    const loader = new Loader({
      apiKey: 'AIzaSyANbCKwjNfz5_DNsl2vK2kzA1qVHx3Ijss', // Replace with your actual API key
      libraries: ['places'],
    });

    loader.load().then(() => {
      // console.log('Google Maps API loaded');
    });
  }, []);

  const { theme } = useParams();
  const Components = Themes[theme];

  const localStorageLocation = localStorage.getItem('location');
  const localStorageStartTime = localStorage.getItem('start_time');
  const localStorageEndTime = localStorage.getItem('end_time');
  const localStorageWeddingDate = localStorage.getItem('wedding_date');
  const selectedfontP = localStorage.getItem('fontP');
  const localStorageTooltip = localStorage.getItem('tooltip_venue');

  const [isOpen, setIsOpen] = useState(localStorageTooltip ? JSON.parse(localStorageTooltip) : true);

  function setTooltip() {
    toggleModal('location');
    setIsOpen(false);
    localStorage.setItem('tooltip_venue', false);
  }

  const locationRef = React.useRef(null);

  const [isLoading, setIsLoading] = useState(false);

  const [location, setLocation] = useState(localStorageLocation || "Corus Hotel, Kuala Lumpur");
  const [startTime, setStartTime] = useState(localStorageStartTime || "11:30");
  const [endTime, setEndTime] = useState(localStorageEndTime || "17:00");
  
  const defaultDate = new Date(2024, 9, 30);

  function convertTo12HourFormat(time24) {
    const [hours, minutes] = time24.split(":");
    const period = hours >= 12 ? "pm" : "am";
    const hours12 = hours % 12 || 12; // Convert 0 to 12 for midnight
  
    return `${hours12}:${minutes} ${period}`;
  }

  const startTimeNew = convertTo12HourFormat(startTime);
  const endTimeNew = convertTo12HourFormat(endTime);

  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);

  const weddingDate = localStorageWeddingDate ? new Date(localStorageWeddingDate) : tomorrow;
  const formattedDate = weddingDate.toLocaleDateString('ms-MY', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });

  const weddingDay = getDayFromDate(weddingDate);

  function getDayFromDate(date) {
    const days = ['Ahad', 'Isnin', 'Selasa', 'Rabu', 'Khamis', "Jumaat", 'Sabtu'];
    const dayIndex = date.getDay();
    localStorage.setItem('day', days[dayIndex]);
    return days[dayIndex];
  }

  const [creatingLocation, setCreatingLocation] = useState(false);

  const handleLocationChange = (event) => {
    const textarea = locationRef.current;
    textarea.style.height = 'auto';
    textarea.style.height = `${textarea.scrollHeight}px`;

    const newLocation = event.target.value;
    setLocation(newLocation);
    localStorage.setItem('location', newLocation);
  };

  const [creatingTime, setCreatingTime] = useState(false);

  const handleStartTimeChange = (event) => {
    const newStartTime = event.target.value;
    setStartTime(newStartTime);
    localStorage.setItem('start_time', newStartTime);
  };

  const handleEndTimeChange = (event) => {
    const newEndTime = event.target.value;
    setEndTime(newEndTime);
    localStorage.setItem('end_time', newEndTime);
  };

  function handleOk() {
    setIsLoading(true);
    setCreatingTime(false);
    localStorage.removeItem('tentative');
    window.location.reload();
  }

  const fontP = (selectedfontP || Components.fontP);

  const [openModalType, setOpenModalType] = useState(null);

  const toggleModal = (modalType) => {
    setIsOpen(false);
    setOpenModalType((prevModalType) =>
      prevModalType === modalType ? null : modalType
    );
  };

  const handleCloseModal = () => {
    setOpenModalType(null);
  };

  const handleOverlayClick = (e) => {
    // Check if the click occurred on the overlay (background)
    if (e.target.classList.contains('overlay')) {
      handleCloseModal();
    }
  };

  return (
    <div className="text-center pt-10 pb-20 md:w-3/5 w-4/5 mx-auto border-t border-gray-700 border-dashed">
      <h1 className={`text-l mt-8 mb-4 uppercase font-bold ${fontP}`}>Tempat</h1>

      {/* <textarea
        value={location}
        // onClick={() => setTooltip()}
        onClick={() => toggleModal('location')}
        onChange={handleLocationChange}
        ref={locationRef}
        className={`input-location ${fontP} bg-transparent w-full text-center h-24`}
      /> */}

      <p
        onClick={() => setTooltip()}
        onChange={handleLocationChange}
        ref={locationRef}
        className={`input-location ${fontP} bg-transparent w-full text-center h-24`}
      >
        {location}
      </p>

      <Tooltip anchorSelect=".input-location" place="top" isOpen={isOpen} variant="error">
        Klik untuk ubah lokasi
      </Tooltip>

      {openModalType === 'location' && (
        <div
          className={`fixed z-20 top-0 left-0 w-full h-full bg-gray-900 bg-opacity-50 flex items-end md:items-center justify-center overlay ${
            window.innerWidth <= 768 ? 'mobile-sheet' : ''
          }`}
          onClick={handleOverlayClick}
        >
          <div
            className={`bg-white py-8 h-auto mb-20 px-3 rounded-lg w-full md:w-1/3 relative ${
              window.innerWidth <= 768 ? 'mobile-sheet-content' : ''
            }`}
          >
            <button
              onClick={handleCloseModal}
              className="absolute top-0 right-0 m-4 text-gray-600 hover:text-gray-800 focus:outline-none"
            >
              <FaTimes />
            </button>
            {/* <LocationSearchInput />  */}
            <CreateLocation />
          </div>
        </div>
      )}

      {/* {location.split('\n').map((text, index) => (
        <p className={`${fontP}`} key={index}>{text}</p>
      ))}
      {creatingLocation ? (
        <div className="mb-2 flex flex-col justify-center items-center">
          <textarea
            value={location}
            onChange={handleLocationChange}
            rows="3"
            className="text-center text-gray-600 mt-5 border p-5"
          />
          <button onClick={() => setCreatingLocation(false)} className="mb-8 text-sm text-white md:px-4 py-3 md:w-14 w-full bg-red-500 hover-bg-red-600 rounded-lg">
            OK
          </button>
        </div>
      ) : (
        <button onClick={() => setCreatingLocation(true)} className="text-sm text-blue-500">Ubah Lokasi</button>
      )} */}

      <h1 className={`text-l mt-4 mb-4 uppercase font-bold ${fontP}`}>Tarikh</h1>
      <p className={`text-l ${fontP}`}>{weddingDay}, {formattedDate}</p>

      <h1 className={`text-l mt-8 mb-4 uppercase font-bold ${fontP}`}>Masa</h1>
      <p className={`text-l ${fontP}`}>{startTimeNew} - {endTimeNew}</p>
      {creatingTime ? (
        <div className="mb-2">
          <input
            type="time"
            value={startTime}
            onChange={handleStartTimeChange}
            className="border border-gray-400 p-2 rounded-lg"
          />
          -
          <input
            type="time"
            value={endTime}
            onChange={handleEndTimeChange}
            className="border border-gray-400 p-2 rounded-lg"
          />
          <button onClick={() => handleOk()} className="text-sm text-white md:px-4 py-3 md:w-14 w-1/5 bg-red-500 hover:bg-red-600 rounded-lg">OK</button>
        </div>
      ) : (
        <button onClick={() => setCreatingTime(true)} className="text-sm text-blue-500">Ubah Masa</button>
      )}
      {isLoading && <LoadingSpinner />}
    </div>
  );
}

export default CreateVenue;
