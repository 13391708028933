import React from 'react';

function LiveSalamKaut({ fontP, salamKaut }) {

  return (
    <div className="flex flex-col items-center">
      <h1 className={`text-l mb-4 uppercase font-bold ${fontP}`}>Salam Kaut</h1>
      <img className='my-6 w-3/4' src={`https://salamkaut.jemputlah.com/images/${salamKaut}`} />
    </div>
  );
}

export default LiveSalamKaut;
