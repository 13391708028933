import React from 'react';
import logo from '../../img/logo-red.png';

function LiveFooter() {
  return (
    <footer className="w-full bg-white py-8 mb-20 z-10">
      <div className="flex flex-col items-center justify-center px-6">
        <a href="/"><img src={logo} alt="Logo" className='h-8' /></a>
        <p className='text-gray-600'>Kad Kahwin Digital Eksklusif. 100% Percuma</p>
      </div>
    </footer>
  );
}

export default LiveFooter;