import React from 'react';
import Themes from '../Themes';

function LiveHero({ hero, theme, walimatulurus, H2, fontP, H2size, groomName, brideName, weddingDate }) {

  const Components = Themes[theme];

  const weddingDateSet = new Date(weddingDate);
  const weddingDay = getDayFromDate(weddingDateSet);

  const formattedDate = weddingDateSet.toLocaleDateString('ms-MY', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });

  // Function to calculate the day from a given date
  function getDayFromDate(date) {
    const days = ['Ahad', 'Isnin', 'Selasa', 'Rabu', 'Khamis', "Jumaat", 'Sabtu'];
    const dayIndex = date.getDay();
    return days[dayIndex];
  }

  const heroStyle = {
    backgroundImage: `url(${Components.bg})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  };

  const center = {
    backgroundImage: `url(${Components.fc})`,
    backgroundSize: 'contain',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
  };  
  
  return (
    <div className="h-screen relative flex flex-col justify-center items-center" style={heroStyle}>
      
        <div className={`transition-opacity duration-1000 ${hero ? 'opacity-100' : 'opacity-0'} ${Components.fcStyle}`} style={center} >
          <p className={`text-l mb-8 ${fontP} uppercase`}>{walimatulurus}</p>
          <div>
            <h2 className={`${H2size} ${H2} capitalize`}>
              {groomName}
            </h2>
            <p className={`text-3xl ${H2} capitalize`}>&</p>
            <h2 className={`${H2size} ${H2} capitalize`}>
              {brideName}
            </h2>
          </div>
          <div className="mt-8">
            <p className={`text-l ${fontP} mb-2`}>{weddingDay}, {formattedDate}</p>
          </div>
        </div>
      <img
        src={Components.ft}
        
        className={Components.ftStyle}
      />
      <img
        src={Components.fb}
        
        className={Components.fbStyle}
      />
    </div>
  );
}

export default LiveHero;
