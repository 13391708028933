import bg1 from '../img/bg1.jpg';
import ft1 from '../img/ft1.png';
import fb1 from '../img/fb1.png';

import bg2 from '../img/bg2.jpg';
import ft2 from '../img/ft2.png';
import fb2 from '../img/fb2.png';

import bg3 from '../img/bg3.jpg';
import ft3 from '../img/ft3.png';
import fb3 from '../img/fb3.png';

import bg4 from '../img/bg4.jpg';
import ft4 from '../img/ft4.png';
import fb4 from '../img/fb4.png';

import bg5 from '../img/bg5.jpg';
import ft5 from '../img/ft5.png';
import fb5 from '../img/fb5.png';

import bg6 from '../img/bg6.jpg';
import ft6 from '../img/ft6.png';
import fb6 from '../img/fb6.png';
import fc6 from '../img/fc6.png';

import bg7 from '../img/bg7.jpg';
import ft7 from '../img/ft7.png';
import fb7 from '../img/fb7.png';
import fc7 from '../img/fc7.png';

import bg8 from '../img/bg8.jpg';
import ft8 from '../img/ft8.png';
import fb8 from '../img/fb8.png';

import bg9 from '../img/bg9.jpg';
import fc9 from '../img/fc9.png';

import bg10 from '../img/bg10.jpg';
import fc10 from '../img/fc10.png';

import bg11 from '../img/bg11.jpg';
import ft11 from '../img/ft11.png';
import fb11 from '../img/fb11.png';
import fc11 from '../img/fc11.png';

import bg12 from '../img/bg12.jpg';
import fc12 from '../img/fc12.png';

import bg13 from '../img/bg13.jpg';
import ft13 from '../img/ft13.png';
import fb13 from '../img/fb13.png';

import bg14 from '../img/bg14.jpg';
import ft14 from '../img/ft14.png';
import fb14 from '../img/fb14.png';
import fc14 from '../img/fc14.png';

import bg15 from '../img/bg15.jpg';
import fc15 from '../img/fc15.png';

import bg16 from '../img/bg16.jpg';
import fc16 from '../img/fc16.png';

import bg17 from '../img/bg17.jpg';
import fc17 from '../img/fc17.png';

import bg18 from '../img/bg18.jpg';
import ft18 from '../img/ft18.png';
import fb18 from '../img/fb18.png';

import bg19 from '../img/bg19.jpg';
import ft19 from '../img/ft19.png';
import fb19 from '../img/fb19.png';

const themes = {
  theme1: {
    bg: bg1,
    ft: ft1,
    fb: fb1,
    fc: '',
    ftStyle: `absolute top-0 right-0 md:w-1/3 sm:w-1/2 w-2/3`,
    fbStyle: `absolute bottom-0 left-0 md:w-1/3 sm:w-1/2 w-2/3`,
    fcStyle: `text-center p-20 md:w-1/2 sm:w-2/3 w-full text-black space-y-8`,
    tStyle: `text-center p-10 md:px-20 md:py-10 border border-gray-700`,
    cd1: "bg-red-200 p-4 rounded-lg",
    cd2: "bg-red-300 p-4 rounded-lg",
    cd3: "bg-red-400 p-4 rounded-lg",
    cd4: "bg-red-500 p-4 rounded-lg",
    H2: "font-great-vibes",
    H2size: "text-5xl",
    fontP: "font-normal",
  },
  theme2: {
    bg: bg2,
    ft: ft2,
    fb: fb2,
    fc: '',
    ftStyle: `absolute top-0 right-0 sm:w-2/3 md:w-2/5 w-full`,
    fbStyle: `absolute bottom-0 left-0 sm:w-2/3 md:w-2/5 w-full`,
    fcStyle: `text-center p-20 md:w-1/2 sm:w-2/3 w-full text-black space-y-8`,
    tStyle: `text-center p-10 md:px-20 md:py-10 border border-gray-700`,
    cd1: "bg-green-200 p-4 rounded-lg",
    cd2: "bg-green-300 p-4 rounded-lg",
    cd3: "bg-green-400 p-4 rounded-lg",
    cd4: "bg-green-500 p-4 rounded-lg",
    H2: "font-monte-carlo",
    H2size: "text-6xl",
    fontP: "font-normal",
  },
  theme3: {
    bg: bg3,
    ft: ft3,
    fb: fb3,
    fc: '',
    ftStyle: `absolute top-0 right-0 sm:w-1/3 md:w-1/5 w-1/3`,
    fbStyle: `absolute bottom-0 left-0 sm:w-2/3 md:w-2/5 w-full`,
    fcStyle: `text-center z-30 p-20 md:w-1/2 sm:w-2/3 w-full text-black space-y-8`,
    tStyle: `text-center p-10 md:px-20 md:py-10 border border-gray-700`,
    cd1: "bg-green-200 p-4 rounded-lg",
    cd2: "bg-green-300 p-4 rounded-lg",
    cd3: "bg-green-400 p-4 rounded-lg",
    cd4: "bg-green-500 p-4 rounded-lg",
    H2: "font-dancing-script",
    H2size: "text-6xl",
    fontP: "font-lato",
  },
  theme4: {
    bg: bg4,
    ft: ft4,
    fb: fb4,
    fc: '',
    ftStyle: `absolute top-0 left-0 sm:w-3/5 md:w-2/5 w-4/5`,
    fbStyle: `absolute bottom-0 right-0 sm:w-2/5 md:w-1/3 w-2/3`,
    fcStyle: `text-center p-20 md:w-1/2 sm:w-2/3 w-full text-black space-y-8`,
    tStyle: `text-center p-10 md:px-20 md:py-10 border border-gray-700`,
    cd1: "bg-gray-200 p-4 rounded-lg",
    cd2: "bg-gray-300 p-4 rounded-lg",
    cd3: "bg-gray-400 p-4 rounded-lg",
    cd4: "bg-gray-500 p-4 rounded-lg",
    H2: "font-playfair-display",
    H2size: "text-5xl",
    fontP: "font-normal",
  },
  theme5: {
    bg: bg5,
    ft: ft5,
    fb: fb5,
    fc: '',
    ftStyle: `absolute top-0 right-0 sm:w-1/3 md:w-1/4 w-2/5`,
    fbStyle: `absolute bottom-0 left-0 sm:w-1/3 md:w-1/4 w-2/5`,
    fcStyle: `text-center p-20 md:w-1/2 sm:w-2/3 w-full text-black space-y-8`,
    tStyle: `text-center p-10 md:px-20 md:py-10 border border-gray-700`,
    cd1: "bg-gray-200 p-4 rounded-lg",
    cd2: "bg-gray-300 p-4 rounded-lg",
    cd3: "bg-gray-400 p-4 rounded-lg",
    cd4: "bg-gray-500 p-4 rounded-lg",
    H2: "font-dancing-script",
    H2size: "text-6xl",
    fontP: "font-lato",
  },
  theme6: {
    bg: bg6,
    ft: ft6,
    fb: fb6,
    fc: fc6,
    ftStyle: `absolute top-0 right-0 sm:w-2/3 md:w-2/3 w-full`,
    fbStyle: `absolute bottom-0 left-0 sm:w-2/3 md:w-2/3 w-full`,
    fcStyle: `text-center p-20 md:w-1/2 sm:w-2/3 w-full text-black space-y-36 md:space-y-8`,
    tStyle: `text-center p-10 md:px-20 md:py-10 border border-gray-700`,
    cd1: "bg-gray-200 p-4 rounded-lg",
    cd2: "bg-gray-300 p-4 rounded-lg",
    cd3: "bg-gray-400 p-4 rounded-lg",
    cd4: "bg-gray-500 p-4 rounded-lg",
    H2: "font-playfair-display",
    H2size: "text-5xl",
    fontP: "font-montserrat",
  },
  theme7: {
    bg: bg7,
    ft: ft7,
    fb: fb7,
    fc: fc7,
    ftStyle: `absolute top-0 left-0 sm:w-2/3 md:w-1/3 w-3/5`,
    fbStyle: `absolute bottom-0 right-0 sm:w-2/3 md:w-1/3 w-3/5`,
    fcStyle: `text-center p-20 md:w-1/2 sm:w-2/3 w-full text-black space-y-36 md:space-y-8`,
    tStyle: `text-center p-10 md:px-20 md:py-10 border border-gray-700`,
    cd1: "bg-gray-200 p-4 rounded-lg",
    cd2: "bg-gray-300 p-4 rounded-lg",
    cd3: "bg-gray-400 p-4 rounded-lg",
    cd4: "bg-gray-500 p-4 rounded-lg",
    H2: "font-ephesis",
    H2size: "text-6xl",
    fontP: "font-normal",
  },
  theme8: {
    bg: bg8,
    ft: ft8,
    fb: fb8,
    fc: '',
    ftStyle: `absolute top-0 left-0 sm:w-2/3 md:w-1/3 w-3/4`,
    fbStyle: `absolute bottom-0 right-0 sm:w-2/3 md:w-1/3 w-3/4`,
    fcStyle: `text-center p-20 md:w-1/2 sm:w-2/3 w-full text-black space-y-8`,
    tStyle: `text-center p-10 md:px-20 md:py-10 border border-gray-700`,
    cd1: "bg-gray-200 p-4 rounded-lg",
    cd2: "bg-gray-300 p-4 rounded-lg",
    cd3: "bg-gray-400 p-4 rounded-lg",
    cd4: "bg-gray-500 p-4 rounded-lg",
    H2: "font-monte-carlo",
    H2size: "text-6xl",
    fontP: "font-normal",
  },
  theme9: {
    bg: bg9,
    ft: '',
    fb: '',
    fc: fc9,
    ftStyle: `absolute top-0 left-0 sm:w-2/3 md:w-1/3 w-3/5`,
    fbStyle: `absolute bottom-0 right-0 sm:w-2/3 md:w-1/3 w-3/5`,
    fcStyle: `text-center p-20 md:w-1/2 sm:w-2/3 w-full text-black space-y-20 md:space-y-8`,
    tStyle: `text-center p-10 md:px-20 md:py-10 border border-gray-700`,
    cd1: "bg-gray-200 p-4 rounded-lg",
    cd2: "bg-gray-300 p-4 rounded-lg",
    cd3: "bg-gray-400 p-4 rounded-lg",
    cd4: "bg-gray-500 p-4 rounded-lg",
    H2: "font-playfair-display",
    H2size: "text-5xl",
    fontP: "font-lato",
  },
  theme10: {
    bg: bg10,
    ft: '',
    fb: '',
    fc: fc10,
    ftStyle: `absolute top-0 right-0 sm:w-2/3 md:w-2/3 w-full`,
    fbStyle: `absolute bottom-0 left-0 sm:w-2/3 md:w-2/3 w-full`,
    fcStyle: `text-center p-20 md:p-40 md:w-1/2 sm:w-2/3 w-full text-black space-y-40 md:space-y-8`,
    tStyle: `text-center p-10 md:px-20 md:py-10 border border-gray-700`,
    cd1: "bg-green-200 p-4 rounded-lg",
    cd2: "bg-green-300 p-4 rounded-lg",
    cd3: "bg-green-400 p-4 rounded-lg",
    cd4: "bg-green-500 p-4 rounded-lg",
    H2: "font-playfair-display",
    H2size: "text-5xl",
    fontP: "font-montserrat",
  },
  theme11: {
    bg: bg11,
    ft: ft11,
    fb: fb11,
    fc: fc11,
    ftStyle: `absolute top-0 right-0 sm:w-2/3 md:w-1/4 w-2/3`,
    fbStyle: `absolute bottom-0 left-0 sm:w-2/3 md:w-1/4 w-2/3`,
    fcStyle: `text-center p-20 md:p-40 md:w-1/2 sm:w-2/3 w-full text-white space-y-32 md:space-y-8`,
    tStyle: `text-center text-white p-10 md:px-20 md:py-10 border border-yellow-200`,
    cd1: "bg-yellow-100 p-4 rounded-lg",
    cd2: "bg-yellow-200 p-4 rounded-lg",
    cd3: "bg-yellow-300 p-4 rounded-lg",
    cd4: "bg-yellow-400 p-4 rounded-lg",
    H2: "font-dancing-script",
    H2size: "text-5xl",
    fontP: "font-lato",
  },
  theme12: {
    bg: bg12,
    ft: '',
    fb: '',
    fc: fc12,
    ftStyle: `absolute top-0 right-0 sm:w-2/3 md:w-2/3 w-full`,
    fbStyle: `absolute bottom-0 left-0 sm:w-2/3 md:w-2/3 w-full`,
    fcStyle: `text-center p-20 md:p-56 md:w-1/2 sm:w-2/3 w-full text-black space-y-36 md:space-y-8`,
    tStyle: `text-center p-10 md:px-20 md:py-10 bg-white shadow-lg`,
    cd1: "bg-red-200 p-4 rounded-lg",
    cd2: "bg-red-300 p-4 rounded-lg",
    cd3: "bg-red-400 p-4 rounded-lg",
    cd4: "bg-red-500 p-4 rounded-lg",
    H2: "font-playfair-display",
    H2size: "text-4xl",
    fontP: "font-montserrat",
  },
  theme13: {
    bg: bg13,
    ft: ft13,
    fb: fb13,
    fc: '',
    ftStyle: `absolute top-0 right-0 sm:w-2/3 md:w-1/3 w-3/5`,
    fbStyle: `absolute bottom-0 left-0 sm:w-2/3 md:w-1/3 w-3/5`,
    fcStyle: `text-center p-20 md:w-1/2 sm:w-2/3 w-full text-blue-100 space-y-8 md:space-y-8`,
    tStyle: `text-center p-10 md:px-20 md:py-10 text-blue-100 border border-blue-200`,
    cd1: "bg-blue-200 p-4 rounded-lg",
    cd2: "bg-blue-300 p-4 rounded-lg",
    cd3: "bg-blue-400 p-4 rounded-lg",
    cd4: "bg-blue-500 p-4 rounded-lg",
    H2: "font-playfair-display",
    H2size: "text-5xl",
    fontP: "font-montserrat",
  },
  theme14: {
    bg: bg14,
    ft: ft14,
    fb: fb14,
    fc: fc14,
    ftStyle: `absolute top-0 right-0 sm:w-2/3 md:w-1/4 w-1/2`,
    fbStyle: `absolute bottom-0 left-0 sm:w-2/3 md:w-1/4 w-1/2`,
    fcStyle: `text-center p-20 md:p-40 md:w-1/2 sm:w-2/3 w-full text-black space-y-44 md:space-y-8`,
    tStyle: `text-center text-black p-10 md:px-20 md:py-10 border border-purple-300`,
    cd1: "bg-blue-200 p-4 rounded-lg",
    cd2: "bg-blue-300 p-4 rounded-lg",
    cd3: "bg-blue-400 p-4 rounded-lg",
    cd4: "bg-blue-500 p-4 rounded-lg",
    H2: "font-tangerine",
    H2size: "text-6xl",
    fontP: "font-lato",
  },
  theme15: {
    bg: bg15,
    ft: '',
    fb: '',
    fc: fc15,
    ftStyle: `absolute top-0 right-0 sm:w-2/3 md:w-1/4 w-1/2`,
    fbStyle: `absolute bottom-0 left-0 sm:w-2/3 md:w-1/4 w-1/2`,
    fcStyle: `text-center p-20 md:p-40 md:w-1/2 sm:w-2/3 w-full text-black space-y-44 md:space-y-8`,
    tStyle: `text-center text-black p-10 md:px-20 md:py-10 border border-red-400`,
    cd1: "bg-gray-200 p-4 rounded-lg",
    cd2: "bg-gray-300 p-4 rounded-lg",
    cd3: "bg-gray-400 p-4 rounded-lg",
    cd4: "bg-gray-500 p-4 rounded-lg",
    H2: "font-great-vibes",
    H2size: "text-5xl",
    fontP: "font-montserrat",
  },
  theme16: {
    bg: bg16,
    ft: '',
    fb: '',
    fc: fc16,
    ftStyle: `absolute top-0 right-0 sm:w-2/3 md:w-1/4 w-1/2`,
    fbStyle: `absolute bottom-0 left-0 sm:w-2/3 md:w-1/4 w-1/2`,
    fcStyle: `text-center p-20 md:p-40 md:w-1/2 sm:w-2/3 w-full text-black space-y-40 md:space-y-4`,
    tStyle: `text-center text-black p-10 md:px-20 md:py-10 border border-gray-600`,
    cd1: "bg-gray-200 p-4 rounded-lg",
    cd2: "bg-gray-300 p-4 rounded-lg",
    cd3: "bg-gray-400 p-4 rounded-lg",
    cd4: "bg-gray-500 p-4 rounded-lg",
    H2: "font-dancing-script",
    H2size: "text-5xl",
    fontP: "font-lato",
  },
  theme17: {
    bg: bg17,
    ft: '',
    fb: '',
    fc: fc17,
    ftStyle: `absolute top-0 right-0 sm:w-2/3 md:w-1/4 w-1/2`,
    fbStyle: `absolute bottom-0 left-0 sm:w-2/3 md:w-1/4 w-1/2`,
    fcStyle: `text-center p-20 md:p-52 md:w-1/2 sm:w-2/3 w-full text-yellow-100 space-y-52 md:space-y-8`,
    tStyle: `text-center text-yellow-100 p-10 md:px-20 md:py-10 border border-yellow-100`,
    cd1: "bg-yellow-100 p-4 rounded-lg",
    cd2: "bg-yellow-200 p-4 rounded-lg",
    cd3: "bg-yellow-300 p-4 rounded-lg",
    cd4: "bg-yellow-400 p-4 rounded-lg",
    H2: "font-dancing-script",
    H2size: "text-5xl",
    fontP: "font-lato",
  },
  theme18: {
    bg: bg18,
    ft: ft18,
    fb: fb18,
    fc: '',
    ftStyle: `absolute top-0 left-0 sm:w-2/3 md:w-1/4 w-4/5`,
    fbStyle: `absolute bottom-0 right-0 sm:w-2/3 md:w-1/4 w-4/5`,
    fcStyle: `text-center p-20 md:p-40 md:w-1/2 sm:w-2/3 w-full text-black space-y-8 md:space-y-8`,
    tStyle: `text-center text-black p-10 md:px-20 md:py-10 border border-gray-600`,
    cd1: "bg-pink-200 p-4 rounded-lg",
    cd2: "bg-pink-300 p-4 rounded-lg",
    cd3: "bg-pink-400 p-4 rounded-lg",
    cd4: "bg-pink-500 p-4 rounded-lg",
    H2: "font-ephesis",
    H2size: "text-6xl",
    fontP: "font-montserrat",
  },
  theme19: {
    bg: bg19,
    ft: ft19,
    fb: fb19,
    fc: '',
    ftStyle: `absolute top-0 left-0 sm:w-2/3 md:w-1/5 w-1/2`,
    fbStyle: `absolute top-0 right-0 sm:w-2/3 md:w-1/5 w-1/2`,
    fcStyle: `text-center p-20 md:p-40 md:w-1/2 sm:w-2/3 w-full text-red-300 space-y-8 md:space-y-8`,
    tStyle: `text-center text-red-300 p-10 md:px-20 md:py-10 border border-red-300`,
    cd1: "bg-gray-200 p-4 rounded-lg",
    cd2: "bg-gray-300 p-4 rounded-lg",
    cd3: "bg-gray-400 p-4 rounded-lg",
    cd4: "bg-gray-500 p-4 rounded-lg",
    H2: "font-playfair-display",
    H2size: "text-5xl",
    fontP: "font-montserrat",
  },
};

export default themes;
