import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { FaSpinner } from 'react-icons/fa';
import logo from '../img/logo-red.png';

import LiveHero from '../components/live/LiveHero';
import LiveInviteMessage from '../components/live/LiveInviteMessage';
import LiveVenue from '../components/live/LiveVenue';
import LiveTentative from '../components/live/LiveTentative';
import LiveCountdown from '../components/live/LiveCountdown';
import LiveBottomBar from '../components/live/LiveBottomBar';
import LiveNavBar from '../components/live/LiveNavBar';
import { Helmet } from 'react-helmet';
import BackgroundMusic from '../components/BackgroundMusic';
import LiveFooter from '../components/live/LiveFooter';
import LiveUcapan from '../components/live/LiveUcapan';

function Live() {
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [hero, setHero] = useState(false);
  const [music, setMusic] = useState(false);
  const [ytId, setYtId] = useState('U4i_pEsQFz4');
  const [ytStart, setYtStart] = useState('0');
  const [scroll, setScroll] = useState('');

  const [theme, setTheme] = useState('');
  const [walimatulurus, setWalimatulurus] = useState('');
  const [walimatulurus2, setWalimatulurus2] = useState('');
  const [doa, setDoa] = useState('');
  const [toggleWalima, setToggleWalima] = useState('');
  const [groomName, setGroomName] = useState('');
  const [brideName, setBrideName] = useState('');
  const [weddingDate, setWeddingDate] = useState('');
  const [parentName, setParentName] = useState('');
  const [message, setMessage] = useState('');
  const [groomFullname, setGroomFullname] = useState('');
  const [brideFullname, setBrideFullname] = useState('');
  const [location, setLocation] = useState('');
  const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');
  const [tentative, setTentative] = useState('');
  const [contacts, setContacts] = useState('');
  const [directions, setDirections] = useState('');
  const [H2, setH2] = useState('');
  const [fontP, setFontP] = useState('');
  const [H2size, setH2size] = useState('');
  const [giftButton, setGiftButton] = useState('');
  const [gift, setGift] = useState(true);
  const [salamKaut, setSalamKaut] = useState('');
  const [rsvp, setRsvp] = useState('');
  const [status, setStatus] = useState('');
  const [createdAt, setCreatedAt] = useState('');
  const [expired, setExpired] = useState(false);
  
  document.body.style.overflow = scroll;

  const client = () => {
    axios.get(`https://api.jemputlah.com/api/clients/${id}`)
      .then(response => {
        const music = JSON.parse(response.data.music);
        const yt_id = response.data.yt_id;
        const start_time = response.data.start_time;
        const gift = response.data.gift;
        const salam_kaut = response.data.salam_kaut;
        const rsvp = response.data.rsvp;

        if (music) {
          setMusic(music);
          setScroll('hidden');
          if(yt_id){
            setYtId(yt_id);
          }
          if(start_time){
            setYtStart(start_time);
          }
          else {
            setYtId('U4i_pEsQFz4');
          }
          
        } else {
          setMusic(false);
          setScroll('auto');
          setHero(true);
        }

        if(gift) {
          setGift(gift);
        }
        if(salam_kaut) {
          setSalamKaut(salam_kaut);
        }
        if(rsvp) {
          setRsvp(JSON.parse(rsvp));
        }
      })
      .catch(error => {
          console.error('Error fetching data:', error);
      });
  };  

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`https://api.jemputlah.com/api/kad/${id}`);
        const data = response.data;
  
        setTheme(data.theme);
        setWalimatulurus(data.walimatulurus);
        setWalimatulurus2(data.walimatulurus2);
        setDoa(data.doa);
        setToggleWalima(data.toggle_walima);
        setGroomName(data.groom_name);
        setBrideName(data.bride_name);
        setWeddingDate(data.wedding_date);
        setParentName(data.parent_name);
        setMessage(data.message);
        setGroomFullname(data.groom_fullname);
        setBrideFullname(data.bride_fullname);
        setLocation(data.location);
        setStartTime(data.start_time);
        setEndTime(data.end_time);
        setTentative(data.tentative);
        setContacts(data.contacts);
        setDirections(data.directions);
        setH2(data.H2);
        setFontP(data.fontP);
        setH2size(data.H2size);
        setGiftButton(data.gift_button);
        setStatus(data.status);
        setCreatedAt(data.created_at);
  
        await client();
        setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setLoading(false);
      }
    };
  
    fetchData();
  }, [id]);

  useEffect(() => {
    // Function to calculate the time difference
    const calculateTimeLeft = () => {
      const expirationTime = new Date(createdAt).getTime() + 24 * 60 * 60 * 1000;
      const currentTime = new Date().getTime();
      const difference = expirationTime - currentTime;

      if (difference > 0) {
        setExpired(false);
      } else {
        setExpired(true);
      }
    };

    // Update the countdown every second
    const interval = setInterval(calculateTimeLeft, 1000);

    // Cleanup the interval on component unmount
    return () => clearInterval(interval);
  }, [createdAt]);
  
  const description = `${walimatulurus} | ${groomName} x ${brideName}`;

  return (
    <div>
      <Helmet>
        <title>{description}</title>
        <meta name="description" content={description} />
        <meta name="robots" content="noindex, nofollow" />
        <meta property="og:title" content={description} />
      </Helmet>
      {status === 'unverified' ? (
          <div>
            {expired ? (
              <div className="flex flex-col text-center space-y-10 items-center justify-center mx-auto max-w-md w-full h-screen">
                <p>Maaf. Tempoh percubaan Kad Kahwin Digital ini sudah luput. Sila log masuk untuk aktifkan Kad Kahwin Digital ini.</p>
                <img src={logo} alt="Logo" className="h-8 mb-4" />
              </div>
            ) : (
              <div>
                {loading ? ( 
                    <div className="fixed inset-0 flex flex-col items-center justify-center space-y-5">
                      <FaSpinner className="text-4xl text-red-400 animate-spin" />
                      <img src={logo} alt="Logo" className="h-8 mb-4" />
                    </div>
                  ) : (
                    <div>
                      {/* <LiveNavBar /> */}
                      <LiveHero hero={hero} theme={theme} walimatulurus={walimatulurus} H2={H2} fontP={fontP} H2size={H2size} groomName={groomName} brideName={brideName} weddingDate={weddingDate} />
                      <LiveInviteMessage walimatulurus2={walimatulurus2} parentName={parentName} message={message} groomFullname={groomFullname} brideFullname={brideFullname} H2={H2} fontP={fontP} />
                      <LiveVenue location={location} startTime={startTime} endTime={endTime} weddingDate={weddingDate} fontP={fontP} />
                      <LiveTentative theme={theme} tentative={tentative} fontP={fontP} />
                      <LiveCountdown theme={theme} doa={doa} weddingDate={weddingDate} fontP={fontP} /> 
                      <LiveUcapan fontP={fontP} />
                      <LiveBottomBar gift={gift} contacts={contacts} directions={directions} weddingDate={weddingDate} startTime={startTime} endTime={endTime} fontP={fontP} salamKaut={salamKaut} rsvp={rsvp} />
                      {music && (
                        <BackgroundMusic setScroll={setScroll} setHero={setHero} videoId={ytId} videoStart={ytStart} walimatulurus={walimatulurus} groomName={groomName} brideName={brideName} H2={H2} fontP={fontP} H2size={H2size} />
                      )}
                      <LiveFooter />
                    </div>
                  )
                }
              </div>
            )}
          </div>
        ):(
          <div>
            {loading ? ( 
                <div className="fixed inset-0 flex flex-col items-center justify-center space-y-5">
                  <FaSpinner className="text-4xl text-red-400 animate-spin" />
                  <img src={logo} alt="Logo" className="h-8 mb-4" />
                </div>
              ) : (
                <div>
                  {/* <LiveNavBar /> */}
                  <LiveHero hero={hero} theme={theme} walimatulurus={walimatulurus} H2={H2} fontP={fontP} H2size={H2size} groomName={groomName} brideName={brideName} weddingDate={weddingDate} />
                  <LiveInviteMessage walimatulurus2={walimatulurus2} parentName={parentName} message={message} groomFullname={groomFullname} brideFullname={brideFullname} H2={H2} fontP={fontP} />
                  <LiveVenue location={location} startTime={startTime} endTime={endTime} weddingDate={weddingDate} fontP={fontP} />
                  <LiveTentative theme={theme} tentative={tentative} fontP={fontP} />
                  <LiveCountdown theme={theme} doa={doa} weddingDate={weddingDate} fontP={fontP} /> 
                  <LiveUcapan fontP={fontP} />
                  <LiveBottomBar gift={gift} contacts={contacts} directions={directions} weddingDate={weddingDate} startTime={startTime} endTime={endTime} fontP={fontP} salamKaut={salamKaut} rsvp={rsvp} />
                  {music && (
                    <BackgroundMusic setScroll={setScroll} setHero={setHero} videoId={ytId} videoStart={ytStart} walimatulurus={walimatulurus} groomName={groomName} brideName={brideName} H2={H2} fontP={fontP} H2size={H2size} />
                  )}
                  <LiveFooter />
                </div>
              )
            }
          </div>
        )
      }
    </div>
  );
}

export default Live;
