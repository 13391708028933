import React from 'react';
import NavBarFixed from '../components/NavBarFixed';
import { Helmet } from 'react-helmet';

const Invalid = () => {
  return (
    <div>
      <Helmet>
        <title>Pautan Tidak Sah | Jemputlah.com</title>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <NavBarFixed />
      <div className="min-h-screen flex items-center justify-center bg-gray-100">
        <div className="bg-white p-8 rounded-md shadow-md max-w-md w-full text-center">
          <svg
            className="text-red-500 w-12 h-12 mx-auto mb-4"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M6 18L18 6M6 6l12 12"
            ></path>
          </svg>
          <h1 className="text-2xl font-bold mb-4">Pautan Tidak Sah</h1>
          <p className="text-gray-700 mb-4">
            Maaf. Pautan ini tidak sah.
          </p>
          <div className="text-center mt-12 mb-6">
            <a
              className='text-green-500 hover:text-green-600 font-semibold'
              href="https://api.whatsapp.com/send?phone=60102701320&text=Masalah%20Pautan%20Pengesahan%20Akaun%20Jemputlah.com"
              target="_blank"
              rel="noreferrer"
            >
              Ada apa-apa pertanyaan? Klik sini untuk WhatsApp kami.
            </a>
          </div>
        </div>
      </div>

    </div>
  );
};

export default Invalid;
