import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import ResponsiveEmbed from 'react-responsive-embed';
import axios from 'axios';

const VideoId = () => {
  const { id } = useParams();
  const [url, setUrl] = useState('');
  const [card, setCard] = useState(false);
  const [selectedVideoId, setSelectedVideoId] = useState('U4i_pEsQFz4');
  const [startTime, setStartTime] = useState('0');
  const [playVideo, setPlayVideo] = useState(true);

  const cardUrl = `https://jemputlah.com/kad/${id}`;

  const videoIds = [
    { id: 'U4i_pEsQFz4', title: 'A Thousand Years' },
    { id: '7rc2y2P5BgQ', title: 'Beautiful in White' },
    { id: '82vC07ygl0A', title: 'Perfect' },
    { id: 'bbAurDvlikM', title: 'You Are The Reason' },
    { id: 'MLDHyBVVAbA', title: 'Saat Bahagia' },
    { id: 'hl4VRYKqFN8', title: 'Berakhirlah Penantianku' },
    { id: 'B5XDa4QqP_E', title: 'Memori Tercipta' },
    { id: 'CzoCeMsa66U', title: 'Kau Tercipta' },
    { id: 's_FQB8odeyc', title: 'Satu Shaf Di Belakangku' },
    { id: 'yppqxahvYCk', title: 'Di Renjis Renjis' },
    { id: 'P74C6cOuKv4', title: 'Selamat Pengantin Baru' },
    { id: 'dAtFzidmmsg', title: 'Kompang Dipalu' },
  ];

  const extractVideoId = () => {
    const regex = /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
    const match = url.match(regex);

    if (match && match[1]) {
      setSelectedVideoId(match[1]);
      setPlayVideo(true); // Set to true to play the video
      setCard(false);
    } else {
      setSelectedVideoId('Invalid URL or Video ID not found');
      setPlayVideo(false); // Set to false to stop playing the video
    }
  };

  const handleVideoButtonClick = (videoId) => {
    setSelectedVideoId(videoId);
    setPlayVideo(true); // Set to true to play the video
    setCard(false);
  };

  const setMusic = () => {
    axios.put(`https://api.jemputlah.com/api/update-ytid/${id}`, { yt_id: selectedVideoId, start_time: startTime })
      .then(response => {
          console.log(response.data.message); 
          setCard(true);
      })
      .catch(error => {
          console.error('Error updating music:', error);
      });
  };

  const convertToSeconds = (time) => {
    if (time.includes(':')) {
      const [minutes, seconds] = time.split(':').map(Number);
      return minutes * 60 + seconds;
    } else {
      return Number(time);
    }
  };

  const handleStartTimeChange = (e) => {
    const value = e.target.value;
    if (/^\d+(:\d{2})?$/.test(value)) { // validate MM:SS or SS format
      const seconds = convertToSeconds(value);
      setStartTime(seconds);
    } else {
      setStartTime(0); // reset if the input is invalid
    }
  };

  const opts = {
    playerVars: {
      autoplay: 1, // Autoplay the video
    },
  };

  return (
    <div>
      {playVideo && (
        <div className="mt-4">
          <ResponsiveEmbed
            src={`https://www.youtube.com/embed/${selectedVideoId}?start=${startTime}`}
            autoPlay
            allowFullScreen
          />
        </div>
      )}
      <button
        onClick={setMusic}
        className='w-full rounded-lg my-4 text-black font-bold capitalize px-5 py-3 bg-yellow-400 hover:bg-yellow-500'
      >
        Pilih lagu ini
      </button>
      {card && (
        <a
          href={cardUrl}
          target="_blank"
          rel="noreferrer"
          className='block text-center w-full rounded-lg mb-4 text-red-400 hover:text-white font-bold capitalize px-5 py-3 border border-red-400 hover:border-red-500 hover:bg-red-500'
        >
          Lihat Kad
        </a>
      )}
      <div className="flex mt-2">
        <input
          type="text"
          id="youtubeUrl"
          value={url}
          placeholder='Masukkan link YouTube lagu pilihan'
          onChange={(e) => setUrl(e.target.value)}
          className="flex-1 p-2 border-b border-gray-300 focus:outline-none focus:border-blue-500"
        />
        <button
          onClick={extractVideoId}
          className="ml-2 px-4 py-2 bg-red-400 text-white rounded hover:bg-red-500 focus:outline-none"
        >
          Play
        </button>
      </div>
      <div className='flex'>
        <label className='flex py-2'>Start at</label>
        <input
          type="text"
          placeholder='0:20'
          onChange={handleStartTimeChange}
          className="flex-1 p-2 border-b border-gray-300 focus:outline-none focus:border-blue-500"
        />
      </div>

      <div className="mt-4">
        <label htmlFor="videoButtons" className="text-gray-600">Atau pilih lagu dibawah:</label>
        <div className="flex flex-wrap gap-2 mt-2">
          {videoIds.map((video) => (
            <button
              key={video.id}
              onClick={() => handleVideoButtonClick(video.id)}
              className="px-4 py-2 bg-red-400 text-white rounded-full hover:bg-red-500 focus:outline-none"
            >
              {video.title}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default VideoId;
