import React from 'react';
import Themes from '../Themes';

function DemoInviteMessage() {
  const localStorageTheme = localStorage.getItem('theme');
  const localStorageWalimatulurus = localStorage.getItem('walimatulurus2');
  const localStorageParentName = localStorage.getItem('parent_name');
  const localStorageMessage = localStorage.getItem('message');
  const localStorageGroomFullName = localStorage.getItem('groom_fullname');
  const localStorageBrideFullName = localStorage.getItem('bride_fullname');
  const selectedH2 = localStorage.getItem('H2');
  const selectedfontP = localStorage.getItem('fontP');

  const theme = (localStorageTheme || "theme6");
  const Components = Themes[theme];

  const walimatulurus = localStorageWalimatulurus;
  const parentName = localStorageParentName || "Abdul Razak bin Abdullah\n&\nAminah binti Ali";
  const message = localStorageMessage || "Dengan penuh kesyukuran, kami menjemput Dato' | Datin | Tuan | Puan | Encik | Cik seisi keluarga hadir ke majlis perkahwinan anakanda kami";
  const GroomFullName = localStorageGroomFullName || "Muhammad Adam bin Abdul Razak";
  const BrideFullName = localStorageBrideFullName || "Siti Hawa binti Faizal";
  
  const H2 = (selectedH2 || Components.H2);
  const fontP = (selectedfontP || Components.fontP);

  return (
    <div className="text-center pt-20 pb-10 md:w-3/5 w-4/5 mx-auto">
      <h1 className={`text-l mb-8 ${fontP} capitalize`}>{walimatulurus}</h1>

      <div className={`text-3xl ${H2}`}>
        {parentName.split('\n').map((text, index) => (
          <p key={index}>{text}</p>
        ))}
      </div>

      <div className={`text-l my-8 ${fontP}`}>
        {message.split('\n').map((text, index) => (
          <p key={index}>{text}</p>
        ))}
      </div>

      <div className={`text-3xl ${H2}`}>
        {GroomFullName.split('\n').map((text, index) => (
          <p key={index}>{text}</p>
        ))}
      </div>

      <p className={`text-3xl ${H2}`}>&</p>

      <div className={`text-3xl ${H2}`}>
        {BrideFullName.split('\n').map((text, index) => (
          <p key={index}>{text}</p>
        ))}
      </div>

      {/* <p className={`text-3xl ${H2}`}>{GroomFullName}</p>

      <p className={`text-3xl ${H2}`}>&</p>

      <p className={`text-3xl ${H2}`}>{BrideFullName}</p> */}
      
    </div>
  );
}

export default DemoInviteMessage;
