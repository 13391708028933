import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import NavBar from '../components/NavBar';
import BillplzLogo from '../img/billplz.png';
import { FaSpinner } from 'react-icons/fa';
import { Helmet } from 'react-helmet';
import BackButtonWhite from '../components/BackButtonWhite';
import axios from 'axios';

const Checkout = () => {
  const { id } = useParams();
  const [promoCode, setPromoCode] = useState('');
  const [name, setName] = useState('');  // Add name state
  const [email, setEmail] = useState('');  // Add name state
  // const email = localStorage.getItem('email');
  const [mobile, setMobile] = useState('');  // Add email state
  const [subtotal, setSubtotal] = useState(50.0);
  const [discount, setDiscount] = useState(0);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({
    name: '',
    mobile: '',
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`https://api.jemputlah.com/api/kad/${id}`);
        const data = response.data;

        setName(data.name);
        setEmail(data.email);
        setMobile(data.mobile);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [id]); 

  const applyPromoCode = () => {
    setLoading(true);
      const sanitizedPromoCode = promoCode.replace(/\s/g, '').toLowerCase();

      axios.get(`https://api.jemputlah.com/api/promocode/${sanitizedPromoCode}`)
        .then(response => {
          const matchedPromoCode = response.data;
          if (matchedPromoCode.expirationDate) {
            const currentDate = new Date();
            if (currentDate <= new Date(matchedPromoCode.expirationDate)) {
              // console.log(matchedPromoCode.discount);
              const discountInt = parseInt(matchedPromoCode.discount);
              setDiscount(discountInt);
              setLoading(false);
              setSuccessMessage('Promo code applied successfully!');
              setErrorMessage('');
            } else {
              setDiscount(0);
              setLoading(false);
              setSuccessMessage('');
              setErrorMessage('This promo code has expired. Please try a different one.');
            }
          } else {
            const discountInt = parseInt(matchedPromoCode.discount);
            setDiscount(discountInt);
            setLoading(false);
            setSuccessMessage('Promo code applied successfully!');
            setErrorMessage('');
          }
        })
        .catch(error => {
            console.error('Error fetching data:', error);
            setDiscount(0);
            setSuccessMessage('');
            setLoading(false);
            setErrorMessage('Invalid promo code. Please try again.');
        });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    // Use the appropriate state-setting function based on the input field's name
    if (name === 'fullName') {
      setName(value);
    } else if (name === 'mobile') {
      setMobile(value);
    } else if (name === 'promoCode') {
      setPromoCode(value);
    }
    // Add more conditions for other fields if needed
  };

  const pay = (promo, amount) => {
    const payURL = `https://pay.jemputlah.com/billplzpost.php?clientId=${id}&name=${name}&email=${email}&mobile=${mobile}&amount=${amount}&promo_code=${promo}&reference_1_label=&reference_2_label=`;
    window.location.href = payURL;
  }

  const handleSubmit = () => {
    // Validate the form
    let formIsValid = true;

    if (!name) {
      setErrors((prevErrors) => ({ ...prevErrors, name: 'Sila masukkan nama anda.' }));
      formIsValid = false;
    }

    if (!mobile) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        mobile: 'Sila masukkan nombor telefon anda.',
      }));
      formIsValid = false;
    }

    if (!email) {
      setErrors((prevErrors) => ({ ...prevErrors, email: 'Sila masukkan email anda.' }));
      formIsValid = false;
    }

    // Check if all required fields are provided
    if (formIsValid) {
      const total = subtotal - discount;
      const amount = Math.round(total * 100);
      
      if (promoCode.trim() === '') {
        pay('none', amount);
      } else {
        pay(promoCode, amount);
      }
      
      // if (promoCode.trim() === '') {
      //   const publishURL = `/publish/${name}/${email}/${mobile}/${total}/none`;
      //   window.location.href = publishURL;
      // } else {
      //   const publishURL = `/publish/${name}/${email}/${mobile}/${total}/${promoCode}`;
      //   window.location.href = publishURL;
      // }
    } else {

    }
  };
  
  return (
    <div>
      <Helmet>
        <title>Checkout | Jemputlah.com</title>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <BackButtonWhite />
      <NavBar />
      <div className="py-24 px-8">
        <div className="md:max-w-xl md:mx-auto bg-white p-4 md:p-8 md:rounded md:shadow-lg">
          <h1 className="text-3xl mb-6 text-center font-semibold">Checkout</h1>

          {/* Product Summary */}
          <div className="mb-6">
            <h2 className="text-lg font-semibold mb-2">Product Summary</h2>
            <p className="text-gray-700">Item: Kad Kahwin Digital</p>
            <p className="text-gray-700">Price: RM50.00</p>
          </div>

          {/* Billing Information */}
          <div className="mb-6">
            <h2 className="text-lg font-semibold mb-2">Billing Information</h2>
            <div className="mb-4">
              <label htmlFor="fullName" className="block text-sm font-medium text-gray-600">
                Name
              </label>
              <input
                type="text"
                id="fullName"
                name="fullName"
                className="mt-1 p-2 w-full border rounded-md"
                placeholder="Masukkan nama anda disini"
                required
                value={name}  // Update to use the state variable
                onChange={handleChange}  // Update to use the handleChange function
              />
              {errors.name && <p className="text-red-500 text-sm mt-1">{errors.name}</p>}
            </div>
            <div className="mb-4">
              <label htmlFor="mobile" className="block text-sm font-medium text-gray-600">
                Phone Number
              </label>
              <div className='flex'>
                <input
                  type="tel"
                  id="mobile"
                  name="mobile"
                  className="mt-1 p-2 w-full border rounded-md"
                  placeholder="Masukkan nombor telefon anda disini"
                  required
                  value={mobile}  // Update to use the state variable
                  onChange={handleChange}  // Update to use the handleChange function
                />
              </div>
              {errors.mobile && <p className="text-red-500 text-sm mt-1">{errors.mobile}</p>}
            </div>
            <div className="mb-4">
              <label htmlFor="email" className="block text-sm font-medium text-gray-600">
                Email
              </label>
              <input
                type="email"
                id="email"
                name="email"
                className="mt-1 p-2 w-full border rounded-md"
                value={email}
                onChange={handleChange}
                disabled
              />
              {errors.email && <p className="text-red-500 text-sm mt-1">{errors.email}</p>}
            </div>
          </div>

          {/* Promo Code */}
          <div className="mb-6">
            <h2 className="text-lg font-semibold mb-2">Promo Code</h2>
            <div className="flex">
              <input
                type="text"
                id="promoCode"
                name="promoCode"
                className="p-2 w-full border rounded-md"
                placeholder="Masukkan promo code (jika ada)"
                value={promoCode}
                onChange={(e) => setPromoCode(e.target.value)}
              />
              {loading ? ( 
                <div className="">
                  <FaSpinner className="text-4xl text-red-400 animate-spin" />
                </div>
              ) : (
                <div> 
                  <button
                    className="ml-2 bg-red-400 hover:bg-red-500 text-white py-2 px-4 rounded-md font-semibold"
                    type="button"
                    onClick={applyPromoCode}
                  >
                    Apply
                  </button>
                </div>
              )}
            </div>
            {successMessage && <p className="text-green-600">{successMessage}</p>}
            {errorMessage && <p className="text-red-600">{errorMessage}</p>}
          </div>

          {/* Payment Information */}
          <div className="mb-6">
            <h2 className="text-lg font-semibold mb-2">Payment Method</h2>
            <div className="flex items-center mb-4">
              <input
                type="radio"
                id="billplz"
                name="paymentMethod"
                className="mr-2"
                checked
                readOnly
              />
              <label htmlFor="billplz" className="text-gray-700">Billplz (FPX)</label>
            </div>
          </div>

          {/* Order Summary */}
          <div className="mb-6">
            <h2 className="text-lg font-semibold mb-2">Order Summary</h2>
            <div className="flex justify-between mb-2">
              <span>Subtotal:</span>
              <span>RM {subtotal.toFixed(2)}</span>
            </div>
            {discount > 0 && (
              <div className="flex justify-between mb-2">
                <span>Discount:</span>
                <span>- RM {discount.toFixed(2)}</span>
              </div>
            )}
            <div className="flex justify-between">
              <span>Total:</span>
              <span>RM {(subtotal - discount).toFixed(2)}</span>
            </div>
          </div>

          {/* Billplz Logo */}
          <div className="text-center mb-6">
            <img src={BillplzLogo} alt="Billplz Logo" className="mx-auto w-full" />
          </div>

          {/* Place Order Button */}
          <div className="text-center">
            <button
              className="bg-red-400 hover:bg-red-500 text-white py-2 px-4 rounded-md font-semibold"
              type="button"
              onClick={handleSubmit}
            >
              Place Order
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Checkout;
