import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { FaChevronDown, FaChevronUp, FaCheck, FaTrash, FaCheckDouble, FaTimes, FaRedo } from "react-icons/fa";
import axios from 'axios';

function RSVPList({setLoading}) {
  const { id } = useParams();
  const [unCheckedRSVPs, setUnCheckedRSVPs] = useState([]);
  const [checkedRSVPs, setCheckedRSVPs] = useState([]);
  const [notAttendingRSVPs, setNotAttendingRSVPs] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    getRsvp();
  }, [id]);

  const getRsvp = () => {
    axios.get(`https://api.jemputlah.com/api/rsvp/${id}`)
        .then(response => {
          const rsvps = response.data;

          const attendingUnChecked = rsvps.filter(rsvp => (rsvp.attending === '1' && rsvp.checkedIn === '0'));
          const attendingChecked = rsvps.filter(rsvp => (rsvp.attending === '1' && rsvp.checkedIn === '1'));
          const notAttending = rsvps.filter(rsvp => rsvp.attending === '0');

          setUnCheckedRSVPs(attendingUnChecked);
          setCheckedRSVPs(attendingChecked);
          setNotAttendingRSVPs(notAttending);
          setLoading(false);
        })
        .catch(error => {
            console.error('Error fetching data:', error);
        });
  }

  const handleCheckIn = (id) => {
    console.log(id);
    setLoading(true);
    axios.put(`https://api.jemputlah.com/api/update-checkedin/${id}`, { checkedIn: true })
      .then(response => {
        console.log(response.data);
        getRsvp();
      })
      .catch(error => {
          console.error('Error checked in:', error);
      });
  };

  const handleUndo = (id) => {
    console.log(id);
    setLoading(true);
    axios.put(`https://api.jemputlah.com/api/update-checkedin/${id}`, { checkedIn: false })
      .then(response => {
        console.log(response.data);
        getRsvp();
      })
      .catch(error => {
          console.error('Error checked in:', error);
      });
  };

  const handleDelete = (id, name) => {
    // Show confirmation alert
    const isConfirmed = window.confirm(`Anda pasti nak padam ${name} dari RSVP?`);
  
    if (isConfirmed) {
      console.log(id);
      setLoading(true);
      axios.delete(`https://api.jemputlah.com/api/delete-rsvp/${id}`)
        .then(response => {
          console.log(response.data);
          getRsvp();
        })
        .catch(error => {
          console.error('Error deleting RSVP:', error);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };
  

  const [openIndex, setOpenIndex] = useState(null);

  const toggleAccordion = (id) => {
    setOpenIndex(openIndex === id ? null : id);
  };

  const renderRSVPs = (rsvps) => (
    <ul>
      {rsvps
        .filter(rsvp => 
          rsvp.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
          rsvp.phoneNumber.includes(searchTerm)
        )
        .map((rsvp, index) => (
          <li key={rsvp.id} className="mb-4">
            {/* Accordion Header */}
            <div
              className="flex justify-between items-center cursor-pointer p-4 bg-white border rounded-lg shadow hover:shadow-lg transition-shadow duration-300"
              onClick={() => toggleAccordion(rsvp.id)}
            >
              <p className="text-lg font-semibold">{rsvp.name}</p>
              {openIndex === rsvp.id ? <FaChevronUp className="w-6 h-6" /> : <FaChevronDown className="w-6 h-6" />}
            </div>

            {/* Accordion Content */}
            <div
              className={`overflow-hidden transition-all duration-500 ease-in-out ${
                openIndex === rsvp.id ? 'max-h-screen opacity-100' : 'max-h-0 opacity-0'
              }`}
            >
              <div className="p-4 bg-white border-t border-gray-300">
                <div className="">
                  <p className="text-md"><span className='font-bold'>No. Telefon:</span> {rsvp.phoneNumber}</p>
                  <p className="text-md"><span className='font-bold'>Emel:</span> {rsvp.email}</p>
                  <p className="text-md"><span className='font-bold'>Jumlah tetamu:</span> {rsvp.guests} orang</p>
                </div>
                {rsvp.ucapan && rsvp.ucapan.length > 0 && (
                  <div>
                    <p className='text-md font-bold'>Ucapan:</p>
                    <p className='text-md p-4 border border-gray-300 mt-2 rounded-md'>{rsvp.ucapan}</p>
                  </div>
                )}

                <div className="mt-12">
                  {rsvp.attending === '1' ? (
                    <div>
                      {rsvp.checkedIn === '0' ? (
                        <div className='flex justify-between space-x-2'>
                          <button
                            onClick={() => handleCheckIn(rsvp.id)}
                            className="border-green-500 border hover:bg-green-500 text-green-500 hover:text-white px-5 py-3 flex justify-center items-center w-full rounded"
                          >
                            Hadir <FaCheck className='ml-2'/>
                          </button>
                          <button
                            onClick={() => handleDelete(rsvp.id, rsvp.name)}
                            className="bg-red-500 text-white px-5 py-3 flex justify-center items-center rounded"
                          >
                            <FaTrash />
                          </button>
                        </div>
                      ) : (
                        <div className='flex justify-between space-x-2'>
                          <button
                            className="bg-gray-300 text-gray-700 px-5 py-3 flex justify-center items-center w-full rounded"
                            disabled
                          >
                            Sudah Hadir <FaCheckDouble  className='ml-2'/>
                          </button>
                          <button
                            onClick={() => handleUndo(rsvp.id)}
                            className="bg-blue-500 text-white px-5 py-3 flex justify-center items-center rounded"
                          >
                            <FaRedo />
                          </button>
                        </div>
                      )}
                    </div>
                  ) : (
                    <div>
                      <button
                        onClick={() => handleDelete(rsvp.id, rsvp.name)}
                        className="bg-red-500 text-white px-5 py-3 flex justify-center items-center w-full rounded"
                      >
                        Padam <FaTrash className='ml-2' />
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </li>
        ))}
    </ul>
  );

  const totalUnChecked = unCheckedRSVPs.reduce((total, rsvp) => total + parseInt(rsvp.guests), 0);
  const totalChecked = checkedRSVPs.reduce((total, rsvp) => total + parseInt(rsvp.guests), 0);
  const totalNotAttendingGuests = notAttendingRSVPs.reduce((total, rsvp) => total + parseInt(rsvp.guests), 0);

  return (
    <div className="max-w-lg">
      <h2 className="text-2xl font-semibold mb-4">List of RSVPs</h2>

      <div className="mb-4">
        <input
          type="text"
          placeholder="Search by name or phone number"
          className="w-full border rounded py-2 px-3 focus:outline-none focus:ring focus:border-blue-300"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>
      <div>
        {unCheckedRSVPs.length > 0 && (
          <div className='mb-8'>
            <h3 className="text-xl font-semibold mb-2">Belum Hadir</h3>
            <p className="text-gray-700 mb-4">Jumlah Tetamu: {totalUnChecked}</p>
            {renderRSVPs(unCheckedRSVPs)}
          </div> 
        )}

        {checkedRSVPs.length > 0 && (
          <div className='mb-8'>
            <h3 className="text-xl font-semibold mb-2">Sudah Hadir</h3>
            <p className="text-gray-700 mb-4">Jumlah Tetamu: {totalChecked}</p>
            {renderRSVPs(checkedRSVPs)}
          </div>
        )}
  
        {notAttendingRSVPs.length > 0 && (
          <div>
            <h3 className="text-xl font-semibold mb-2">Tidak Hadir</h3>
            <p className="text-gray-700 mb-4">Jumlah Tetamu: {totalNotAttendingGuests}</p>
            {renderRSVPs(notAttendingRSVPs)}
          </div>
        )}
  
        {(checkedRSVPs.length === 0 && notAttendingRSVPs.length === 0) && (
          <p>No RSVPs submitted yet.</p>
        )}
      </div>
    </div>
  );
}

export default RSVPList;
