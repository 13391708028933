import React, { useState, useEffect } from 'react';
import { FaPhone, FaWhatsapp } from 'react-icons/fa';

function LiveContacts({ contacts }) {

  const storedContacts = JSON.parse(contacts);
  
  return (
    <div className="relative">
      {storedContacts.length === 0 ? (
        <div className='border p-2 my-2 rounded flex items-center justify-between'>
          <p>Tiada nombor telefon untuk dihubungi</p>
        </div>
      ) : (
        storedContacts.map((contact, index) => (
          <div key={index} className="border p-2 my-2 rounded flex items-center justify-between">
            <p>{contact.name}</p>
            <div className="flex space-x-2">
              {contact.contactMethod === 'whatsapp' && (
                <a
                  className="flex items-center justify-center w-12 h-12 rounded-full bg-green-500 text-white text-2xl"
                  href={`https://wa.me/6${contact.phoneNumber}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaWhatsapp />
                </a>
              )}
              {contact.contactMethod === 'phonecall' && (
                <a
                  className="flex items-center justify-center w-12 h-12 rounded-full bg-blue-500 text-white text-2xl"
                  href={`tel://+6${contact.phoneNumber}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaPhone />
                </a>
              )}
              {contact.contactMethod === 'both' && (
                <>
                  <a
                    className="flex items-center justify-center w-12 h-12 rounded-full bg-blue-500 text-white text-2xl"
                    href={`tel://+6${contact.phoneNumber}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaPhone />
                  </a>
                  <a
                    className="flex items-center justify-center w-12 h-12 rounded-full bg-green-500 text-white text-2xl"
                    href={`https://wa.me/6${contact.phoneNumber}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaWhatsapp />
                  </a>
                </>
              )}
            </div>
          </div>
        ))
      )}
    </div>
  );
}

export default LiveContacts;
