import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import logo from '../img/logo-white.png';
import { Helmet } from 'react-helmet';
import axios from 'axios';
import { FaAddressBook, FaGift, FaHeart, FaMusic, FaSpinner, FaWhatsapp } from 'react-icons/fa';

const Manage = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [event, setEvent] = useState('');
  const [walimatulurus, setWalimatulurus] = useState('');
  const [status, setStatus] = useState('');
  const [groomName, setGroomName] = useState('');
  const [brideName, setBrideName] = useState('');
  const [weddingDate, setWeddingDate] = useState('');
  const [createdAt, setCreatedAt] = useState('');
  const [timeLeft, setTimeLeft] = useState({ hours: 0, minutes: 0, seconds: 0 });
  const [expired, setExpired] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`https://api.jemputlah.com/api/kad/${id}`);
        const data = response.data;

        setWalimatulurus(data.walimatulurus);
        setStatus(data.status);
        setGroomName(data.groom_name);
        setBrideName(data.bride_name);
        setWeddingDate(data.wedding_date);
        setCreatedAt(data.created_at);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [id]); 

  useEffect(() => {
    const getEvent = async () => {
      axios.get(`https://api.jemputlah.com/api/clients/${id}`)
        .then(response => {
          const event = response.data.event;
          setEvent(event);
        })
        .catch(error => {
            console.error('Error fetching data:', error);
        });
    };

    getEvent();

  }, [id]);

  useEffect(() => {
    // Function to calculate the time difference
    const calculateTimeLeft = () => {
      const expirationTime = new Date(createdAt).getTime() + 24 * 60 * 60 * 1000;
      const currentTime = new Date().getTime();
      const difference = expirationTime - currentTime;

      if (difference > 0) {
        const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
        const minutes = Math.floor((difference / (1000 * 60)) % 60);
        const seconds = Math.floor((difference / 1000) % 60);
        setTimeLeft({ hours, minutes, seconds });
      } else {
        // If the expiration time has passed
        setTimeLeft({ hours: 0, minutes: 0, seconds: 0 });
        setExpired(true);
      }
    };

    // Update the countdown every second
    const interval = setInterval(calculateTimeLeft, 1000);

    // Cleanup the interval on component unmount
    return () => clearInterval(interval);
  }, [createdAt]);

  const weddingDateSet = new Date(weddingDate);
  const weddingDay = getDayFromDate(weddingDateSet);

  const formattedDate = weddingDateSet.toLocaleDateString('ms-MY', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });

  // Function to calculate the day from a given date
  function getDayFromDate(date) {
    const days = ['Ahad', 'Isnin', 'Selasa', 'Rabu', 'Khamis', "Jumaat", 'Sabtu'];
    const dayIndex = date.getDay();
    return days[dayIndex];
  }
  
  const url = `https://jemputlah.com/kad/${id}`;
  const edit = `/edit/${id}`;
  const rsvp = `/rsvp/${id}`;
  const music = `/music/${id}`;
  const salamkaut = `/salamkaut/${id}`;
  const aktifkan = `/checkout/${id}`;

  const handleShare = () => {

    if (navigator.share) {
      navigator.share({
        title: walimatulurus,
        text: `Jemputan ${walimatulurus} ${event}`,
        url: url,
      })
        .then(() => console.log('Successful share'))
        .catch((error) => console.error('Error sharing:', error));
    } else {
      alert(`Share this link: ${url}`);
    }
  };

  return (
    <div>
      {/* <WhatsAppFAB />  */}
      <Helmet>
        <title>Tahniah | Jemputlah.com</title>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      {loading ? ( 
          <div className="fixed inset-0 flex flex-col items-center justify-center space-y-5">
            <FaSpinner className="text-4xl text-red-400 animate-spin" />
            <img src={logo} alt="Logo" className="h-8 mb-4" />
          </div>
        ) : (
          <div>
            <header className="w-full fixed bg-red-400 shadow-md py-4 z-10">
              <div className="flex items-center justify-center px-6">
                <a href="/"><img src={logo} alt="Logo" className='h-8' /></a>
              </div>
            </header>
            <div className="bg-gray-50 flex pt-20 justify-center min-h-screen">
              <div className=" px-8 py-8 max-w-md w-full">

                {status === 'unverified' ?(
                  <div className='flex items-center justify-between pb-2'>
                    <div>
                      {expired ? (
                        <p>Status: <span className='font-bold text-red-500'>Luput</span></p> // Display "Expired" when the time is up
                      ) : (
                        <>
                          <p>Status: <span className='font-bold'>Trial</span></p>
                          <p className='text-sm'>Luput dalam: {timeLeft.hours}j: {timeLeft.minutes}m: {timeLeft.seconds}s</p>
                        </>
                      )}
                    </div>
                    <a
                      href={aktifkan}
                      rel="noreferrer"
                      className='px-3 py-2 rounded-lg bg-purple-600 hover:bg-purple-700 text-white'>
                        Aktifkan
                    </a>
                  </div>
                ):(
                  <div className='pb-2'>
                    <p>Status: <span className='font-bold text-green-500'>Aktif</span></p>
                  </div>
                )}

                <div className='relative px-5 py-2 rounded-lg shadow-xl mb-8 bg-white'>
                  {/* Icons in the background */}
                  <div className="absolute inset-0 flex justify-center items-center opacity-30">
                    {/* Adjust the number of icons and their size as needed */}
                    <FaHeart className="text-red-300 text-6xl mx-2" />
                    <FaHeart className="text-red-300 text-6xl mx-2" />
                    <FaHeart className="text-red-300 text-6xl mx-2" />
                  </div>
  
                  {/* Content */}
                  <div className='relative py-8'>
                    <p className='capitalize'>{walimatulurus}</p>
                    <h2 className='text-2xl font-bold'>{groomName} & {brideName}</h2>
                    <p>{weddingDay}, {formattedDate}</p>
                  </div>
                </div>
                <div className='text-center'>
                  <input
                    type="text"
                    value={url}
                    readOnly
                    className="border px-5 py-3 mb-2 w-full"
                  />
                  <button
                    onClick={handleShare}
                    className="bg-red-400 hover:bg-red-500 text-white px-3 py-2 rounded w-full"
                  >
                    Share
                  </button>
                  <div className='flex items-center justify-between space-x-2 mt-2'>
                    <a
                      href={url}
                      target="_blank"
                      rel="noreferrer"
                      className="bg-white text-center text-red-400 hover:bg-red-400 hover:text-white px-3 py-2 rounded w-full border-solid border-2 border-red-400"
                    >
                      
                      View
                    </a>
                    <a
                      href={edit}
                      target="_blank"
                      rel="noreferrer"
                      className="bg-white text-center text-red-400 hover:bg-red-400 hover:text-white px-3 py-2 rounded w-full border-solid border-2 border-red-400"
                    >
                      Edit
                    </a>
                  </div>
                  <div className='my-6 flex items-center justify-around'>
                    <a
                      href={rsvp}
                      target="_blank"
                      rel="noreferrer"
                      className="bg-white hover:bg-red-500 text-red-500 hover:text-white p-3 rounded-lg border border-gray-200 w-1/4 flex flex-col items-center space-y-2"
                    >
                      <FaAddressBook className="text-3xl" />
                      <p className="text-xs">RSVP</p>
                    </a>
                    <a
                      href={music}
                      target="_blank"
                      rel="noreferrer"
                      className="bg-white hover:bg-red-500 text-red-500 hover:text-white p-3 rounded-lg border border-gray-200 w-1/4 flex flex-col items-center space-y-2"
                    >
                      <FaMusic className="text-3xl" />
                      <p className="text-xs">Lagu</p>
                    </a>
                    <a
                      href={salamkaut}
                      target="_blank"
                      rel="noreferrer"
                      className="bg-white hover:bg-red-500 text-red-500 hover:text-white p-3 rounded-lg border border-gray-200 flex flex-col items-center space-y-2"
                    >
                      <FaGift className="text-3xl" />
                      <p className="text-xs">Hadiah & Salam Kaut</p>
                    </a>
                  </div>
                  {/* <div className='my-6 space-y-2'>
                    <a
                      href={rsvp}
                      target="_blank"
                      rel="noreferrer"
                      className="font-bold text-center bg-green-400 hover:bg-green-500 text-white px-5 py-5 rounded w-full block"
                    >
                      
                      RSVP
                    </a>
                    <a
                      href={music}
                      target="_blank"
                      rel="noreferrer"
                      className="font-bold text-center bg-yellow-500 hover:bg-yellow-600 text-black px-5 py-5 rounded w-full block"
                    >
                      Background Music
                    </a>
                    <a
                      href={salamkaut}
                      target="_blank"
                      rel="noreferrer"
                      className="font-bold text-center bg-purple-500 hover:bg-purple-600 text-white px-5 py-5 rounded w-full block"
                    >
                      Salam Kaut & Hadiah
                    </a>
                  </div> */}
                    <a
                      href='https://wa.me/60102701320'
                      target="_blank"
                      rel="noreferrer"
                      className="flex items-center justify-center font-bold text-center bg-green-500 hover:bg-green-600 text-white px-2 py-2 rounded-lg w-full"
                    >
                      <FaWhatsapp className="mr-2" /> Ada soalan?
                    </a>
                </div>
                {/* <Donate /> */}
                {/* <Feedback /> */}
                {/* <Poll /> */}
                {/* <Servis /> */}
  
              </div>
            </div>
          </div>
        )
      }
    </div>
    
  );
};

export default Manage;
