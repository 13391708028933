import React from 'react';
import Themes from '../Themes';

function LiveTentative({ theme, tentative, fontP }) {

  const Components = Themes[theme];

  const heroStyle = {
    backgroundImage: `url(${Components.bg})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  };

  const aturCaraMajlis = JSON.parse(tentative);

  return (
    <div className="min-h-screen relative flex items-center justify-center" style={heroStyle}>
      <div className="text-center z-10 my-20">
        <div className={Components.tStyle}>
          <h1 className={`text-l mb-8 uppercase font-bold ${fontP}`}>Atur Cara Majlis</h1>
          {aturCaraMajlis.map((item, index) => (
            <div key={index} className={`my-4 ${fontP}`}>
              <p className="text-l">{item.title}:</p>
              <p className="text-l">{item.displaytime}</p>
            </div>
          ))}
        </div>
      </div>
      <img
        src={Components.ft}
        
        className={Components.ftStyle}
      />
      <img
        src={Components.fb}
        
        className={Components.fbStyle}
      />
    </div>
  );
}

export default LiveTentative;
