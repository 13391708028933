import React from 'react';

const Product = ({ image, title, description }) => {
  return (
    <div className="max-w-sm rounded-xl overflow-hidden shadow-lg">
      <img src={image} alt={title} className="w-full h-30 object-cover" />
      <div className="px-1 py-2">
        <p className="text-gray-700 text-sm">{title}</p>
        <div className="font-bold text-sm mb-2">{description}</div>
      </div>
    </div>
  );
};

export default Product;
