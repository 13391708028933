import React from 'react';

function LiveDate({ weddingDate, startTime, endTime, fontP }) {

  function convertTo12HourFormat(time24) {
    const [hours, minutes] = time24.split(":");
    const period = hours >= 12 ? "pm" : "am";
    const hours12 = hours % 12 || 12; // Convert 0 to 12 for midnight
  
    return `${hours12}:${minutes} ${period}`;
  }

  const startTimeNew = convertTo12HourFormat(startTime);
  const endTimeNew = convertTo12HourFormat(endTime);

  const weddingDateSet = new Date(weddingDate);
  const weddingDay = getDayFromDate(weddingDateSet);

  const formattedDate = weddingDateSet.toLocaleDateString('ms-MY', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });

  function getDayFromDate(date) {
    const days = ['Ahad', 'Isnin', 'Selasa', 'Rabu', 'Khamis', "Jumaat", 'Sabtu'];
    const dayIndex = date.getDay();
    return days[dayIndex];
  }

  return (
    <div className="text-center">
      <h1 className={`text-l mb-4 uppercase font-bold ${fontP}`}>Tarikh</h1>
      <p className={`text-l ${fontP}`}>{weddingDay}, {formattedDate}</p>

      <h1 className={`text-l mt-8 mb-4 uppercase font-bold ${fontP}`}>Masa</h1>
      <p className={`text-l ${fontP}`}>{startTimeNew} - {endTimeNew}</p>
    </div>
  );
}

export default LiveDate;
