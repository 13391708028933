import React, {useState, useEffect} from "react";
import FeedbackList from "../components/FeedbackList";
import RatingGraph from "../components/RatingGraph";
import NavBar from "../components/NavBar";
import Loading from "../components/Loading";
import axios from "axios";

const Feedback = () => {
  const [feedback, setFeedback] = useState([]);
  const [ratings, setRatings] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    axios.get("https://api.jemputlah.com/api/feedback")
      .then((response) => {
        const data = response.data;
        const filteredData = data.filter((item) => item.name.trim() !== "");
        const initialRatings = { 1: 0, 2: 0, 3: 0, 4: 0, 5: 0 };
        filteredData.forEach((item) => {
          if (item.rating >= 1 && item.rating <= 5) {
            initialRatings[item.rating]++;
          }
        });
        setRatings(initialRatings);
        setFeedback(data);
        setLoading(false);
      })
      .catch((error) => console.error("Error fetching feedback:", error));
  }, []);

  return (
    <div>
      <NavBar />
      <div className="md:p-24 flex items-center justify-center">
        <div className="bg-white px-8 py-24 md:py-12 rounded-md md:shadow-lg max-w-xl w-full text-center space-y-8">
          <h1 className="text-lg font-semibold">Feedback</h1>
          {loading ? (
            <Loading />
          ):(
            <>
              <RatingGraph ratings={ratings} />
              <FeedbackList feedback={feedback} />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Feedback;
