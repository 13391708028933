import React, { useState, useRef } from 'react';
import MusicActivation from '../components/MusicActivation';

const ActivateMusic = () => {

  return (
    <div className="max-w-md mx-auto mt-8">
      <MusicActivation />
    </div>
  );
};

export default ActivateMusic;
