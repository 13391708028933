import { useState, useEffect } from 'react';
import React, { useParams } from 'react-router-dom';
import axios from 'axios';

const EnableGift = () => {
  const { id } = useParams();
  const [isGiftEnabled, setIsGiftEnabled] = useState(true);

  useEffect(() => {
    axios.get(`https://api.jemputlah.com/api/clients/${id}`)
        .then(response => {
          console.log(response.data.gift);
          const giftStatus = JSON.parse(response.data.gift);
          setIsGiftEnabled(giftStatus);
        })
        .catch(error => {
            console.error('Error fetching data:', error);
        });
  }, []);

  const toggleGift = () => {
    axios.put(`https://api.jemputlah.com/api/update-gift/${id}`, { gift: !isGiftEnabled })
      .then(response => {
          console.log(response.data.message); // Log success message
          setIsGiftEnabled(!isGiftEnabled);
      })
      .catch(error => {
          console.error('Error updating gift:', error);
      });
  };

  return (
    <div className="flex items-center my-5">
      <h1 className='text-red-400 font-bold'>Idea Hadiah Kahwin</h1>
      <input
        type="checkbox"
        checked={isGiftEnabled}
        onChange={toggleGift}
        id="giftToggle"
        className="hidden"
      />
      <label
        htmlFor="giftToggle"
        className="flex items-center cursor-pointer bg-gray-100 w-14 h-7 rounded-full p-1 border border-gray-300 transition duration-300 ease-in-out ml-auto"
      >
        <div
          className={`${
            isGiftEnabled ? 'translate-x-7 bg-green-500' : 'translate-x-0 bg-gray-400'
          } w-5 h-5 rounded-full shadow-md transform transition-transform`}
        ></div>
      </label>
    </div>

  );
};

export default EnableGift;
