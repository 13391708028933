import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { FaCheck, FaCheckDouble, FaTimes } from "react-icons/fa";
import axios from 'axios';

function UploadQr({setLoading}) {
  const { id } = useParams();
  const [selectedFile, setSelectedFile] = useState(null);
  const [salamKaut, setSalamKaut] = useState('');
  const [uploaded, setUploaded] = useState(false);

  useEffect(() => {
    axios.get(`https://api.jemputlah.com/api/clients/${id}`)
      .then(response => {
        const salam_kaut = response.data.salam_kaut;
        if(salam_kaut){
          setSalamKaut(salam_kaut);
          setUploaded(true);
          setLoading(false);
        } else {
          setUploaded(false);
          setLoading(false);
        }
      })
      .catch(error => {
          console.error('Error fetching data:', error);
      });
  }, [id]);

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    const formData = new FormData();
    formData.append('image', selectedFile);

    axios.post(`https://api.jemputlah.com/api/salamkaut/${id}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    .then(response => {
      console.log(response.data);
      window.location.reload();
    })
    .catch(error => {
      console.error('Error uploading image:', error);
      // Handle error, maybe show an error message
    });
  };

  const ubahQr = () => {
    setUploaded(false);
  };

  const deleteQr = () => {
    setLoading(true);
    const formData = new FormData();
    formData.append('card_id', id);

    axios.post(`https://api.jemputlah.com/api/deleteqr`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    .then(response => {
      console.log(response.data);
      window.location.reload();
    })
    .catch(error => {
      console.error('Error uploading image:', error);
      // Handle error, maybe show an error message
    });
  };

  return (
    <div >

      {uploaded ? (
        <div className="max-w-lg">
          <h2 className="text-2xl font-semibold mb-4">Qr Code Salam Kaut Anda</h2>
          <img className='my-6' src={`https://salamkaut.jemputlah.com/images/${salamKaut}`} />
          <div className='space-y-2'>
            <button onClick={ubahQr} className='w-full text-white px-5 py-3 bg-red-400 hover:bg-red-500 rounded-lg'>Ubah QR Code</button>
            <button onClick={deleteQr} className='w-full text-white px-5 py-3 bg-red-500 hover:bg-red-600 rounded-lg'>Padam QR Code</button>
          </div>
        </div>
      ) : (
        <div className="max-w-lg">
          <h2 className="text-2xl font-semibold mb-4">Upload Qr Code untuk Salam Kaut</h2>
          <form onSubmit={handleSubmit}>
            <div className='flex flex-col space-y-4'>
              <input type="file" onChange={handleFileChange} required />
              <button type='submit' className='px-5 py-3 bg-red-400 hover:bg-red-500 text-white rounded-lg'>Upload</button>
            </div> 
          </form>
        </div>   
      )}
    </div>
  );
}

export default UploadQr;
