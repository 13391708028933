import React from 'react';
import qrcode from '../img/qrcodegc.jpg';

const Donate = () => {

  return (
    <div>
      <div className='text-md text-center space-y-4 my-4'>
        {/* <p className='text-2xl'>Kad Kahwin Digital ini <b>PERCUMA</b> sahaja</p>
        <p className='text-3xl font-bold'>Tetapi...</p>
        <p className='text-xl'>Jika berkemampuan, belilah sebagai tanda penghargaan. Bayar ikut kadar kemampuan. 😊</p>
        <a
          href="https://billplz.com/jemputlahcom"
          target="_blank"
          rel="noreferrer"
          className='animate-bounce font-bold text-center bg-yellow-400 hover:bg-yellow-500 text-black px-5 py-5 rounded w-full block'>
            Klik Sini Untuk Membeli
        </a> */}
        <p className='text-3xl'>السلام عليكم</p>
        <p>Bantu admin beri nafkah kepada isteri dan 2 orang anak kecil yg comel. Terima kasih. 😊</p>
        {/* <p>Saya memerlukan dana untuk tambah baik app Kad Kahwin Digital ini.</p> */}
        {/* <div>
          <p>✅ Tambah tema pink & purple</p>
          <p>✅ Tambah animasi</p>
          <p>✅ Fix error</p>
          <p>✅ Renew Server</p>
          <p>✅ Guna Google Maps API untuk set lokasi</p>
          <p>✅ Provide support via Live Chat</p>
        </div>
        <p>Boleh support saya sikit? Terima kasih. 😊</p> */}
        <img src={qrcode} />
        <p className='font-bold'>Atau boleh transfer ke:</p>
        <div>
          <p>Maybank</p>
          <p>5625 2650 8232</p>
          <p>Guru Coklat Enterprise</p>
          <p>Ref: Kad Kahwin Digital</p>
        </div>
        <p>Terima kasih. 😊</p>
      </div>
    </div>
  );
};

export default Donate;
