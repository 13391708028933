import React from 'react';

function LiveInviteMessage({ walimatulurus2, parentName, message, groomFullname, brideFullname, H2, fontP }) {
  
  const walimatulurus = (walimatulurus2 === "null") ? "" : (walimatulurus2 || "Walimatulurus");
  
  return (
    <div className="text-center pt-20 pb-10 md:w-3/5 w-4/5 mx-auto">
      <h1 className={`text-l mb-8 ${fontP} capitalize`}>{walimatulurus}</h1>
      <div className={`text-3xl ${H2}`}>
        {parentName.split('\n').map((text, index) => (
          <p key={index}>{text}</p>
        ))}
      </div>
      <div className={`text-l my-8 ${fontP}`}>
        {message.split('\n').map((text, index) => (
          <p key={index}>{text}</p>
        ))}
      </div>

      <div className={`text-3xl ${H2}`}>
        {groomFullname.split('\n').map((text, index) => (
          <p key={index}>{text}</p>
        ))}
      </div>

      <p className={`text-3xl ${H2}`}>&</p>

      <div className={`text-3xl ${H2}`}>
        {brideFullname.split('\n').map((text, index) => (
          <p key={index}>{text}</p>
        ))}
      </div>

      {/* <p className={`text-3xl ${H2}`}>{groom_fullname}</p>

      <p className={`text-3xl ${H2}`}>&</p>

      <p className={`text-3xl ${H2}`}>{bride_fullname}</p> */}
      
    </div>
  );
}

export default LiveInviteMessage;
