import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import Themes from '../Themes';

function PreviewHero() {
  const { theme } = useParams();
  const Components = Themes[theme];

  // const localStorageGroomName = localStorage.getItem('GroomName');
  // const localStorageBrideName = localStorage.getItem('BrideName');
  // const localStorageWeddingDate = localStorage.getItem('Date');

  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);

  // const [groomName, setGroomName] = useState(localStorageGroomName || "Adam");
  // const [brideName, setBrideName] = useState(localStorageBrideName || "Hawa");
  // const [weddingDate, setWeddingDate] = useState(localStorageWeddingDate ? new Date(localStorageWeddingDate) : tomorrow);
  
  const groomName = "Adam";
  const brideName = "Hawa";
  const weddingDate = tomorrow;
  const weddingDay = getDayFromDate(weddingDate);

  const formattedDate = weddingDate.toLocaleDateString('ms-MY', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });

  // Function to calculate the day from a given date
  function getDayFromDate(date) {
    const days = ['Ahad', 'Isnin', 'Selasa', 'Rabu', 'Khamis', "Jumaat", 'Sabtu'];
    const dayIndex = date.getDay();
    localStorage.setItem('Day', days[dayIndex]);
    return days[dayIndex];
  }

  const heroStyle = {
    backgroundImage: `url(${Components.bg})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  };

  const center = {
    backgroundImage: `url(${Components.fc})`,
    backgroundSize: 'contain',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
  };

  // const selectedH2 = localStorage.getItem('H2');
  // const selectedfontP = localStorage.getItem('fontP');
  // const selectedH2size = localStorage.getItem('H2size');
  const H2 = Components.H2;
  const fontP = Components.fontP;
  const H2size = Components.H2size;
  

  return (
    <div className="h-screen relative flex flex-col justify-center items-center" style={heroStyle}>
      <div className={Components.fcStyle} style={center}>
        <p className={`text-l ${fontP} uppercase`}>Walimatulurus</p>
        <div>
          <h2 className={`${H2size} ${H2} capitalize`}>
            {groomName}
          </h2>
          <p className={`text-3xl ${H2} capitalize`}>&</p>
          <h2 className={`${H2size} ${H2} capitalize`}>
            {brideName}
          </h2>
        </div>
        <div>
          <p className={`text-l ${fontP}`}>{weddingDay}, {formattedDate}</p>
        </div>
      </div>
      <img
        src={Components.ft}
        className={Components.ftStyle}
      />
      <img
        src={Components.fb}
        
        className={Components.fbStyle}
      />
    </div>
  );
}

export default PreviewHero;
