import React, { useState, useRef, useEffect } from 'react';
import YouTube from 'react-youtube';
import { FaPlayCircle, FaPauseCircle, FaSpinner } from "react-icons/fa";

const BackgroundMusic = ({ setScroll, setHero, videoId, videoStart, walimatulurus, groomName, brideName, H2, fontP, H2size }) => {
  
  const playerRef = useRef(null)
  const [isPlaying, setPlaying] = useState(false);
  const [openModalType, setOpenModalType] = useState('contacts');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 3000);
  }, []);

  const onReady = (event) => {
    playerRef.current = event.target;
  };

  const playVideo = () => {
    if (playerRef.current) {
      playerRef.current.playVideo();
    }
    setPlaying(true);
  };

  const pauseVideo = () => {
    if (playerRef.current) {
      playerRef.current.pauseVideo();
    }
    setPlaying(false);
  };

  const opts = {
    width: 1, 
    height: 1,
    playerVars: {
      autoplay: 0,
      start: videoStart,
    },
  };

  const [modalOpacity, setModalOpacity] = useState(1);

  const handleCloseModal = () => {
    // Set the modal opacity to 0 to trigger the fade-out animation
    setModalOpacity(0);
    playVideo();
    setPlaying(true);
    setScroll('auto');
    // Optionally, you can use a setTimeout to remove the modal from the DOM after the animation
    setTimeout(() => {
      setOpenModalType(null);
      setHero(true);
    }, 2000); // Adjust the delay time based on your animation duration
  };

  return (
    <div className="fixed bottom-20 right-0">
      <YouTube videoId={videoId} opts={opts} onReady={onReady}/>
      {isPlaying ? (
        <div>
          <button 
            onClick={() => pauseVideo()}
            className='py-3 px-5 font-lg text-red-400 hover:text-red-500'>
              <FaPauseCircle className='w-6 h-6' />
          </button>
        </div>
      ) : (
        <div>
          <button 
            onClick={() => playVideo()}
            className='py-3 px-5 font-lg text-red-400 hover:text-red-500'>
              <FaPlayCircle className='w-6 h-6' />
          </button>
        </div>
      )}

      {openModalType === 'contacts' && (
        <div
          className={`fixed z-50 top-0 left-0 w-full h-full bg-white bg-opacity-50 flex items-center justify-center overlay ${
            window.innerWidth <= 768 ? 'mobile-sheet' : ''
          }`}
          style={{ opacity: modalOpacity, transition: 'opacity 1s ease-out 1s' }}
        >
          <div
            className={`bg-white py-8 px-3 h-screen w-full flex items-center bg-opacity-20 z-50 ${
            // className={`bg-white py-8 px-3 rounded-full shadow-lg z-50 ${
              window.innerWidth <= 768 ? 'mobile-sheet-content' : ''
            }`}
            style={{ 
              opacity: modalOpacity, 
              transition: 'opacity 1s ease-out 1s'
             }}
          >
            <div 
              className='text-center z-30 p-20 w-full text-black space-y-8'
              style={{ opacity: modalOpacity, transition: 'opacity 1s ease-out' }}
            >
            {loading ? (
              <div className="fixed inset-0 flex flex-col items-center justify-center space-y-5">
                <FaSpinner className="text-4xl text-red-400 animate-spin" />
              </div>
            ) : (
                <div>
                  <p className={`text-l mb-8 ${fontP} uppercase`}>{walimatulurus}</p>
                  <div>
                    <h2 className={`${H2size} ${H2} capitalize`}>
                      {groomName}
                    </h2>
                    <p className={`text-3xl ${H2} capitalize`}>&</p>
                    <h2 className={`${H2size} ${H2} capitalize`}>
                      {brideName}
                    </h2>
                  </div>
                  <button 
                    onClick={handleCloseModal}
                    className='rounded-full my-8 bg-red-400 hover:bg-red-500 text-white px-3 py-1'
                  >
                    Buka
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default BackgroundMusic;
