import React, { useState, useEffect } from 'react';
import { FaMapMarkerAlt, FaWaze } from 'react-icons/fa';

function CreateLocation() {
  const [locations, setLocations] = useState([]);
  const [coordinate, setCoordinate] = useState({
    locationName: '',
    latitude: '',
    longitude: '',
  });

  useEffect(() => {
    const storedLocations = localStorage.getItem('directions');
    if (storedLocations) {
      setLocations(JSON.parse(storedLocations));
    }
  }, []);

  const handleFormSubmit = (e) => {
    e.preventDefault();

    const wazeUrl = `https://waze.com/ul?ll=${coordinate.latitude},${coordinate.longitude}`;
    const googleMapsUrl = `https://www.google.com/maps?q=${coordinate.latitude},${coordinate.longitude}`;

    const newLocation = {
      locationName: coordinate.locationName,
      wazeUrl: wazeUrl,
      googleMapsUrl: googleMapsUrl,
    };

    // Add a new location
    const updatedLocations = [...locations];
    updatedLocations[0] = newLocation;
    setLocations(updatedLocations);
    localStorage.setItem('directions', JSON.stringify(updatedLocations));
    localStorage.setItem('location', coordinate.locationName);
    window.location.reload();

    // Reset the form data
    setCoordinate({
      locationName: '',
      latitude: '',
      longitude: '',
    });
  };

  const handleRemoveLocation = () => {
    // Remove the locations
    setLocations([]);
    localStorage.removeItem('directions');
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCoordinate((prevCoordinate) => ({
      ...prevCoordinate,
      [name]: value,
    }));
  };

  return (
    <div className="relative">
      {/* Existing Locations */}
      {locations.length > 0 && (
        <div className="border p-2 my-2 rounded flex items-center justify-between">
          <p>{locations[0].locationName}</p>
          <div className="flex space-x-2">
            <div className="flex items-center justify-center w-8 h-8 rounded-full bg-red-500 text-white">
              <FaMapMarkerAlt />
            </div>
            <div className="flex items-center justify-center w-8 h-8 rounded-full bg-blue-300 text-black">
              <FaWaze />
            </div>
            <button
              onClick={handleRemoveLocation}
              className="text-red-700 px-2 py-1"
            >
              X
            </button>
          </div>
        </div>
      )}

      {/* Form */}
      <form
        className="flex flex-col"
        onSubmit={handleFormSubmit}
      >
        <label className="mb-2 text-sm">Location Name:</label>
        <input
          type="text"
          name="locationName"
          placeholder="eg: Daun Grand Hall, The Icon, 6th Floor"
          value={coordinate.locationName}
          onChange={handleInputChange}
          className="mb-4 border p-2 rounded"
          required
        />

        <label className="mb-2 text-sm">Latitude:</label>
        <input
          type="text"
          name="latitude"
          placeholder="eg: 3.156525"
          value={coordinate.latitude}
          onChange={handleInputChange}
          className="mb-4 border p-2 rounded"
          required
        />

        <label className="mb-2 text-sm">Longitude:</label>
        <input
          type="text"
          name="longitude"
          placeholder="eg: 101.722336"
          value={coordinate.longitude}
          onChange={handleInputChange}
          className="mb-4 border p-2 rounded"
          required
        />

        <div className="mb-4 flex flex-col space-y-2">
          <label className="text-sm">
            Dapatkan Latitude & Longitude:
            <a
              href="https://www.latlong.net/"
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-500 ml-2"
            >
              Klik Sini
            </a>
          </label>
          <label className="text-sm">Jika keluar error 'Bot Detected', refresh page semula dan masukkan alamat penuh</label>
          <label className="text-sm">Atau boleh follow tutorial ini <a className="text-blue-500" href="https://vt.tiktok.com/ZSYbtQkLB/" target='_blank'>KLIK SINI</a></label>
          <label className="text-sm">Kalau masih tak faham juga, isi je apa-apa. Nanti dah siap daftar semua, boleh hubungi saya. Saya akan set kan. :)</label>
        </div>

        <div className="flex items-center justify-between">
          <button
            type="submit"
            className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 w-full"
          >
            {locations.length > 0 ? 'Update Location' : 'Add Location'}
          </button>
        </div>
      </form>
    </div>
  );
}

export default CreateLocation;
