import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import Themes from '../Themes';

function PreviewVenue() {
  const { theme } = useParams();
  const Components = Themes[theme];

  // const localStorageLocation = localStorage.getItem('Location');
  // const localStorageStartTime = localStorage.getItem('StartTime');
  // const localStorageEndTime = localStorage.getItem('EndTime');
  // const localStorageWeddingDate = localStorage.getItem('Date'); // Correct the key to 'weddingDate' (assuming that's the key you use)
  const location = "Corus Hotel, Kuala Lumpur";
  const startTime = "11:30";
  const endTime = "17:00";

  // const location = (localStorageLocation || "Corus Hotel, Kuala Lumpur");
  // const [startTime, setStartTime] = useState(localStorageStartTime || "11:30");
  // const [endTime, setEndTime] = useState(localStorageEndTime || "17:00");

  function convertTo12HourFormat(time24) {
    const [hours, minutes] = time24.split(":");
    const period = hours >= 12 ? "pm" : "am";
    const hours12 = hours % 12 || 12; // Convert 0 to 12 for midnight
  
    return `${hours12}:${minutes} ${period}`;
  }

  const startTimeNew = convertTo12HourFormat(startTime);
  const endTimeNew = convertTo12HourFormat(endTime);

  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);

  const weddingDate = tomorrow; 
  // const weddingDate = localStorageWeddingDate ? new Date(localStorageWeddingDate) : defaultDate;
  const formattedDate = weddingDate.toLocaleDateString('ms-MY', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });

  const weddingDay = getDayFromDate(weddingDate);

  function getDayFromDate(date) {
    const days = ['Ahad', 'Isnin', 'Selasa', 'Rabu', 'Khamis', "Jumaat", 'Sabtu'];
    const dayIndex = date.getDay();
    localStorage.setItem('Day', days[dayIndex]);
    return days[dayIndex];
  }

  const fontP = Components.fontP;
  // const selectedfontP = localStorage.getItem('fontP');
  // const fontP = (selectedfontP || Components.fontP);

  return (
    <div className="text-center pt-10 pb-20 md:w-3/5 w-4/5 mx-auto border-t border-gray-700 border-dashed">
      <h1 className={`text-l mt-8 mb-4 uppercase font-bold ${fontP}`}>Tempat</h1>
      {location.split('\n').map((text, index) => (
        <p className={`${fontP}`} key={index}>{text}</p>
      ))}

      <h1 className={`text-l mt-8 mb-4 uppercase font-bold ${fontP}`}>Tarikh</h1>
      <p className={`text-l ${fontP}`}>{weddingDay}, {formattedDate}</p>

      <h1 className={`text-l mt-8 mb-4 uppercase font-bold ${fontP}`}>Masa</h1>
      <p className={`text-l ${fontP}`}>{startTimeNew} - {endTimeNew}</p>
    </div>
  );
}

export default PreviewVenue;
