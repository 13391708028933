import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import bg1 from '../img/bg1.jpg';
import ft2 from '../img/ft2.png';
import fb2 from '../img/fb2.png';
import { themes } from '../context/ThemesList';
import NavBar from '../components/NavBar';
import WhatsAppFAB from '../components/WhatsAppFAB';
import TopBanner from '../components/TopBanner';
import { Helmet } from 'react-helmet';
import { FaStar } from 'react-icons/fa';

function Home() {
  const [clientCount, setClientCount] = useState(0);
  const [cardCount, setCardCount] = useState(0);
  const [todayWeddingCount, setTodayWeddingCount] = useState(0);

  useEffect(() => {
    totalUsers();
    totalCards();
    totalTodayWedding();
  }, []);

  const totalUsers = () => {
    axios.get('https://api.jemputlah.com/api/totalusers')
      .then(response => {
        setClientCount(response.data.count);
      })
      .catch(error => {
        console.error("There was an error fetching the client count!", error);
      });
  };

  const totalCards = () => {
    axios.get('https://api.jemputlah.com/api/totalcards')
      .then(response => {
        setCardCount(response.data.count);
      })
      .catch(error => {
        console.error("There was an error fetching the card count!", error);
      });
  };

  const totalTodayWedding = () => {
    axios.get('https://api.jemputlah.com/api/todayweddings')
      .then(response => {
        setTodayWeddingCount(response.data.count);
      })
      .catch(error => {
        console.error("There was an error fetching the card count!", error);
      });
  };

  const heroStyle = {
    backgroundImage: `url(${bg1})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  };

  const H2 = "font-playfair-display";
  const fontP = "font-normal";

  const themesRef = useRef(null);
  const navigate = useNavigate();

  const scrollToThemes = () => {
    themesRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  const redirectToPreview = (themeId) => {
    window.scrollTo(0, 0);
    navigate(`/preview/theme${themeId}`);
  };

  return (
    <div>
      <Helmet>
        <title>Kad Kahwin Digital Eksklusif Percuma</title>
        <meta 
          name="description" 
          content="Kad Kahwin Digital Eksklusif Percuma. Siap RSVP, Ucapan, Background Music dan Salam Kaut" 
        />
        <meta 
          name="keywords" 
          content="Kad Kahwin Digital, Percuma, RSVP, Majlis perkahwinan, majlis pernikahan, kad jemputan, jemputlah, kenduri kesyukuran, tunang, pertunangan, pernikahan, save the date" 
        />
        <link rel="canonical" href="https://jemputlah.com" />
      </Helmet>
      <NavBar />
      <WhatsAppFAB />
      <div className="py-14 md:py-4 min-h-screen relative flex flex-col justify-center items-center" style={heroStyle}>
        <div className="z-10 text-center px-4 mt-20 md:p-20 md:w-1/2 sm:w-2/3 w-3/4 text-black">
          <h2 className={`text-3xl ${H2} capitalize`}>
            Kad Kahwin Digital Eksklusif
          </h2>
          <p className={`mt-5 ${fontP} capitalize`}>
            {/* Kini 100% Percuma */}
            Kini siap RSVP, Salam Kaut, Ucapan & Lagu. Paling penting, senang nak guna.
          </p>

          <div className='px-3 py-2 rounded-lg shadow-lg flex items-center justify-between bg-white mt-12'>
            <p>Harga</p>
            <p>RM50.00</p>
          </div>

          <div className='flex flex-col items-center justify-center space-y-2 md:flex-row md:space-x-2 md:space-y-0 mt-12'>
            <a 
              href={`/kad/demoplus`}
              target="_blank"
              className={`w-full py-2 px-3 bg-white text-red-500 hover:bg-red-500 hover:text-white rounded-lg border-solid border-2 border-red-500 hover:border-red-500 relative z-10`}>
              Demo
            </a>
            <a 
              onClick={scrollToThemes}
              className={`w-full py-2 px-3 bg-red-400 text-white hover:bg-red-500 hover:text-white rounded-lg border-solid border-2 border-red-400 hover:border-red-500 relative z-10`}>
              Free Trial (24 Jam)
            </a>
            <a 
              href={`/dashboard`}
              target="_blank"
              className={`w-full py-2 px-3 bg-pink-400 text-white hover:bg-pink-500 hover:text-white rounded-lg border-solid border-2 border-pink-400 hover:border-pink-500 relative z-10`}>
              Log Masuk
            </a>
            {/* <a 
              href={`/sumbangan`}
              target="_blank"
              className={`w-full text-xl py-3 px-6 bg-green-400 text-white hover:bg-green-500 hover:text-white rounded-lg border-solid border-2 border-green-400 hover:border-green-500 relative z-10`}>
              Sumbangan
            </a>
            <a 
              href={`/sponsor`}
              target="_blank"
              className={`w-full text-xl py-3 px-6 bg-yellow-400 text-black font-bold hover:bg-yellow-500 hover:text-black font-bold rounded-lg border-solid border-2 border-yellow-400 hover:border-yellow-500 relative z-10`}>
              Sponsor
            </a> */}
          </div>
          <div className='flex items-center justify-center space-x-2 mt-4'>
            <div className="w-full p-2 bg-white rounded-lg shadow-md">
              <h2 className="text-sm font-bold text-gray-500">Jumlah pengguna</h2>
              <p className="text-xl font-bold text-gray-800">{clientCount}</p>
              <p className="text-sm">orang</p>
            </div>
            <div className="w-full p-2 bg-white rounded-lg shadow-md">
              <h2 className="text-sm font-bold text-gray-500">Jumlah Kad Tercipta</h2>
              <p className="text-xl font-bold text-gray-800">{cardCount}</p>
            </div>
            <div className="w-full p-2 bg-white rounded-lg shadow-md">
              <h2 className="text-sm font-bold text-gray-500">Kahwin Hari Ini</h2>
              <p className="text-xl font-bold text-gray-800">{todayWeddingCount}</p>
            </div>
          </div>
        </div>
        {/* <img
          src={ft2}
          alt="Top Flower"
          className="absolute top-0 right-0 sm:w-2/3 md:w-2/5 w-full"
        />
        <img
          src={fb2}
          alt="Bottom Flower"
          className="absolute bottom-0 left-0 sm:w-2/3 md:w-2/5 w-full"
        /> */}
      </div>
      <div className="bg-gray-100 md:py-20 py-8 px-4">
        <div className="max-w-4xl mx-auto">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
            <div className="border bg-white rounded-lg overflow-hidden shadow-md">
              <div className="p-6">
                <p className="text-sm text-gray-600 mb-4">
                  Mudah dan simple. Untuk orang seperti saya yang dh overwhelmed
                  fikir banyak benda, kad ni memang pilihan!
                </p>
                <div className="flex justify-between">
                  <h2 className="text-gray-600">Didi</h2>
                  <div className="flex items-center">
                    <FaStar className="w-6 h-6 fill-current text-yellow-500 mr-1" />
                    <span className="text-gray-700">5</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="border bg-white rounded-lg overflow-hidden shadow-md">
              <div className="p-6">
                <p className="text-sm text-gray-600 mb-4">
                  senang nak edit. mudah . 5minit siap
                </p>
                <div className="flex justify-between">
                  <h2 className="text-gray-600">Intan</h2>
                  <div className="flex items-center">
                    <FaStar className="w-6 h-6 fill-current text-yellow-500 mr-1" />
                    <span className="text-gray-700">5</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="border bg-white rounded-lg overflow-hidden shadow-md">
              <div className="p-6">
                <p className="text-sm text-gray-600 mb-4">
                  ya Allah senang sgt ii, thankyou so much sbb bgi promo. btw
                  design simple pon tetap cantik dan trending
                </p>
                <div className="flex justify-between">
                  <h2 className="text-gray-600">Maryam</h2>
                  <div className="flex items-center">
                    <FaStar className="w-6 h-6 fill-current text-yellow-500 mr-1" />
                    <span className="text-gray-700">4</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex justify-center mt-8">
            <a
              href="/feedback"
              className="text-center bg-white hover:bg-red-500 hover:text-white rounded-lg w-full md:w-1/2 px-5 py-3"
            >
              Baca Semua Feedback
            </a>
          </div>
        </div>
      </div>
      <div ref={themesRef} className="container mx-auto py-14">
        <h1 className="text-xl font-bold mb-4 text-center">Pilih Tema</h1>
        <div className="flex flex-wrap">
          {themes.map((theme) => (
            <a href={`/preview/theme${theme.id}`} key={theme.id} className="w-1/2 md:w-1/4 p-4 md:p-4">
              <div className="max-w-sm rounded overflow-hidden shadow-lg my-4">
                <img 
                  src={theme.image} 
                  alt={theme.title} 
                  className="w-full"
                  loading="lazy"
                />
              </div>
            </a>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Home;
