import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Themes from '../Themes';

function PreviewBottomBar() {
  const { theme } = useParams();
  const navigate = useNavigate();

  const Components = Themes[theme];
  const H2 = Components.H2;
  const fontP = Components.fontP;
  const H2size = Components.H2size;

  const pilihTema = () => {
    window.scrollTo(0, 0);
    localStorage.clear();
    localStorage.setItem('theme', theme);
    localStorage.setItem('H2', H2);
    localStorage.setItem('H2size', H2size);
    localStorage.setItem('fontP', fontP);
    navigate(`/create/${theme}`);
  };
  return (
    <header className="fixed bottom-0 left-0 w-full shadow-md py-4 z-10">
      <div className="flex items-center justify-between px-6">
        <button
          onClick={pilihTema}
          className={`text-xl text-white font-bold w-full md:w-1/2 mx-auto py-3 px-5 bg-red-400 hover:bg-red-500 shadow-md rounded-lg`} href="/">
            Pilih Tema Ini
        </button>
      </div>
    </header>
  );
}

export default PreviewBottomBar;