import React, { useState } from "react";
import {
  FaPhone,
  FaTimes,
  FaMapMarkerAlt,
  FaGift,
  FaCalendarCheck,
  FaClock,
} from "react-icons/fa";

import LiveContacts from "./LiveContacts";
import LiveLocation from "./LiveLocation";
import LiveGift from "./LiveGift";
import LiveDate from "./LiveDate";
import LiveRSVP from "./LiveRSVP";
import LiveSalamKaut from "./LiveSalamKaut";

function LiveBottomBar({
  gift,
  contacts,
  directions,
  weddingDate,
  startTime,
  endTime,
  fontP,
  salamKaut,
  rsvp,
}) {
  const [openModalType, setOpenModalType] = useState(null);
  // const storedGiftState = localStorage.getItem('gift_button');
  // const giftState = storedGiftState ? JSON.parse(storedGiftState) : true;
  // const [giftButton, setGiftButton] = useState(giftState);

  const toggleModal = (modalType) => {
    setOpenModalType((prevModalType) =>
      prevModalType === modalType ? null : modalType
    );
  };

  const handleCloseModal = () => {
    setOpenModalType(null);
  };

  const handleOverlayClick = (e) => {
    // Check if the click occurred on the overlay (background)
    if (e.target.classList.contains("overlay")) {
      handleCloseModal();
    }
  };

  const giftButton = JSON.parse(gift);

  return (
    <div>
      <div className="fixed bottom-0 left-0 w-full shadow-md py-2 z-30 bg-white/70">
        <div className="flex items-center justify-between md:justify-center px-3">
          <button
            rel="noopener noreferrer"
            className="flex items-center justify-center p-3"
            onClick={() => toggleModal("contacts")}
          >
            <FaPhone className="contact text-black text-2xl" />
          </button>

          <button
            rel="noopener noreferrer"
            className="flex items-center justify-center p-3"
            onClick={() => toggleModal("location")}
          >
            <FaMapMarkerAlt className="contact text-black text-2xl" />
          </button>
          {rsvp ? (
            <button
              rel="noopener noreferrer"
              className="flex items-center justify-center p-3"
              onClick={() => toggleModal("rsvp")}
            >
              <FaCalendarCheck className="contact text-black text-2xl" />
            </button>
          ) : (
            <></>
          )}
            
          {salamKaut ? (
            <button
              rel="noopener noreferrer"
              className="flex items-center justify-center p-3"
              onClick={() => toggleModal("salamkaut")}
            >
              <FaGift className="contact text-black text-2xl" />
            </button>
          ) : (
            <div>
              {giftButton ? (
                <a
                  rel="noopener noreferrer"
                  className="flex items-center justify-center p-3"
                  href="https://mycollection.shop/ideahadiahkahwin/"
                  target="_blank"
                >
                  <FaGift className="contact text-black text-2xl" />
                </a>
              ) : (
                <></>
              )}
            </div>
          )}

          <button
            rel="noopener noreferrer"
            className="flex items-center justify-center p-3"
            onClick={() => toggleModal("date")}
          >
            <FaClock className="contact text-black text-2xl" />
          </button>
        </div>
      </div>

      {openModalType === "contacts" && (
        <div
          className={`fixed z-50 top-0 left-0 w-full h-full bg-gray-900 bg-opacity-50 flex items-end md:items-center justify-center overlay ${
            window.innerWidth <= 768 ? "mobile-sheet" : ""
          }`}
          onClick={handleOverlayClick}
        >
          <div
            className={`bg-white py-8 h-auto mb-20 px-3 rounded-lg w-full md:w-1/3 relative ${
              window.innerWidth <= 768 ? "mobile-sheet-content" : ""
            }`}
          >
            <button
              onClick={handleCloseModal}
              className="absolute top-0 right-0 m-4 text-gray-600 hover:text-gray-800 focus:outline-none"
            >
              <FaTimes />
            </button>
            <LiveContacts contacts={contacts} />
          </div>
        </div>
      )}

      {openModalType === "location" && (
        <div
          className={`fixed z-50 top-0 left-0 w-full h-full bg-gray-900 bg-opacity-50 flex items-end md:items-center justify-center overlay ${
            window.innerWidth <= 768 ? "mobile-sheet" : ""
          }`}
          onClick={handleOverlayClick}
        >
          <div
            className={`bg-white py-8 h-auto mb-20 px-3 rounded-lg w-full md:w-1/3 relative ${
              window.innerWidth <= 768 ? "mobile-sheet-content" : ""
            }`}
          >
            <button
              onClick={handleCloseModal}
              className="absolute top-0 right-0 m-4 text-gray-600 hover:text-gray-800 focus:outline-none"
            >
              <FaTimes />
            </button>
            <LiveLocation directions={directions} />
          </div>
        </div>
      )}

      {openModalType === "rsvp" && (
        <div
          className={`fixed z-50 top-0 left-0 w-full h-full bg-gray-900 bg-opacity-50 flex items-end md:items-center justify-center overlay ${
            window.innerWidth <= 768 ? "mobile-sheet" : ""
          }`}
          onClick={handleOverlayClick}
        >
          <div
            className={`bg-white py-8 h-5/6 overflow-auto mb-20 px-3 rounded-lg w-full md:w-1/3 relative ${
              window.innerWidth <= 768 ? "mobile-sheet-content" : ""
            }`}
          >
            <button
              onClick={handleCloseModal}
              className="absolute top-0 right-0 m-4 text-gray-600 hover:text-gray-800 focus:outline-none"
            >
              <FaTimes />
            </button>
            <LiveRSVP />
          </div>
        </div>
      )}

      {openModalType === "salamkaut" && (
        <div
          className={`fixed z-50 top-0 left-0 w-full h-full bg-gray-900 bg-opacity-50 flex items-end md:items-center justify-center overlay ${
            window.innerWidth <= 768 ? "mobile-sheet" : ""
          }`}
          onClick={handleOverlayClick}
        >
          <div
            className={`bg-white py-8 h-5/6 overflow-auto mb-20 px-3 rounded-lg w-full md:w-1/3 relative ${
              window.innerWidth <= 768 ? "mobile-sheet-content" : ""
            }`}
          >
            <button
              onClick={handleCloseModal}
              className="absolute top-0 right-0 m-4 text-gray-600 hover:text-gray-800 focus:outline-none"
            >
              <FaTimes />
            </button>
            <LiveSalamKaut fontP={fontP} salamKaut={salamKaut} />
            {giftButton ? (
              <a
                rel="noopener noreferrer"
                className="mx-auto block text-center my-8 px-5 py-3 bg-red-400 hover:bg-red-500 text-white rounded-lg w-4/5 md:w-3/5"
                href="https://mycollection.shop/ideahadiahkahwin/"
                target="_blank"
              >
                Idea Hadiah Kahwin
              </a>
            ) : (
              <></>
            )}
          </div>
        </div>
      )}

      {openModalType === "date" && (
        <div
          className={`fixed z-50 top-0 left-0 w-full h-full bg-gray-900 bg-opacity-50 flex items-end md:items-center justify-center overlay ${
            window.innerWidth <= 768 ? "mobile-sheet" : ""
          }`}
          onClick={handleOverlayClick}
        >
          <div
            className={`bg-white py-8 h-auto mb-20 px-3 rounded-lg w-full md:w-1/3 relative ${
              window.innerWidth <= 768 ? "mobile-sheet-content" : ""
            }`}
          >
            <button
              onClick={handleCloseModal}
              className="absolute top-0 right-0 m-4 text-gray-600 hover:text-gray-800 focus:outline-none"
            >
              <FaTimes />
            </button>
            <LiveDate
              weddingDate={weddingDate}
              startTime={startTime}
              endTime={endTime}
              fontP={fontP}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default LiveBottomBar;
