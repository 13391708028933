import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Themes from '../Themes';

function CreateTentative() {
  const { theme } = useParams();
  const Components = Themes[theme];

  const localStorageStartTime = localStorage.getItem('start_time');
  const localStorageEndTime = localStorage.getItem('end_time');
  const selectedfontP = localStorage.getItem('fontP');

  const heroStyle = {
    backgroundImage: `url(${Components.bg})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  };
  
  const startTime = localStorageStartTime || "11:00";
  const endTime = localStorageEndTime || "17:00";

  function convertTo12HourFormat(time24) {
    const [hours, minutes] = time24.split(":");
    const period = hours >= 12 ? "pm" : "am";
    const hours12 = hours % 12 || 12; // Convert 0 to 12 for midnight
  
    return `${hours12}:${minutes} ${period}`;
  }

  function addMinutesToTime(timeString, minutesToAdd) {
    const [hours, minutes] = timeString.split(':').map(Number);
  
    // Convert everything to minutes
    const totalMinutes = hours * 60 + minutes;
    const newTotalMinutes = totalMinutes + minutesToAdd;
  
    // Calculate new hours and minutes
    const newHours = Math.floor(newTotalMinutes / 60);
    const newMinutes = newTotalMinutes % 60;
  
    // Format the result
    const formattedTime = `${newHours.toString().padStart(2, '0')}:${newMinutes.toString().padStart(2, '0')}`;
  
    return formattedTime;
  }

  const tetamuHadir = addMinutesToTime(startTime, 30);
  const tibaPengantin = addMinutesToTime(tetamuHadir, 60);
  const makanBeradab = addMinutesToTime(tibaPengantin, 60);

  const tetamuHadirNew = convertTo12HourFormat(tetamuHadir);
  const tibaPengantinNew = convertTo12HourFormat(tibaPengantin);
  const makanBeradabNew = convertTo12HourFormat(makanBeradab);
  const endTimeNew = convertTo12HourFormat(endTime);

  const defaultData = [
    { title: "Kehadiran tetamu", displaytime: tetamuHadirNew, time:tetamuHadir},
    { title: "Ketibaan Pengantin", displaytime: tibaPengantinNew, time:tibaPengantin},
    { title: "Makan Beradab", displaytime: makanBeradabNew, time:makanBeradab},
    { title: "Majlis Berakhir", displaytime: endTimeNew, time:endTime},
  ];

  const [aturCaraMajlis, setAturCaraMajlis] = useState(() => {
    // Try to get the data from localStorage
    const storedData = localStorage.getItem('tentative');
    return storedData ? JSON.parse(storedData) : defaultData;
  });

  // Save aturCaraMajlis to localStorage whenever it changes
  useEffect(() => {
    localStorage.setItem('tentative', JSON.stringify(aturCaraMajlis));
    // console.log('tentative: ', JSON.stringify(aturCaraMajlis));
  }, [aturCaraMajlis]);

  const [isCreating, setIsCreating] = useState(false);

  const handleCreateItem = (index, newTitle, newTime) => {
    const updatedAturCaraMajlis = [...aturCaraMajlis];
    updatedAturCaraMajlis[index].title = newTitle;
    updatedAturCaraMajlis[index].time = newTime;
    updatedAturCaraMajlis[index].displaytime = convertTo12HourFormat(newTime);
    setAturCaraMajlis(updatedAturCaraMajlis);
  };

  const handleAddItem = () => {
    const newItem = { title: "New Item", time: "12:00 tengah hari" };
    setAturCaraMajlis([...aturCaraMajlis, newItem]);
  };

  const handleDeleteItem = (index) => {
    const updatedAturCaraMajlis = [...aturCaraMajlis];
    updatedAturCaraMajlis.splice(index, 1);
    setAturCaraMajlis(updatedAturCaraMajlis);
  };

  const handleMoveItemUp = (index) => {
    if (index > 0) {
      const updatedItems = [...aturCaraMajlis];
      const temp = updatedItems[index];
      updatedItems[index] = updatedItems[index - 1];
      updatedItems[index - 1] = temp;
      setAturCaraMajlis(updatedItems);
    }
  };

  const handleMoveItemDown = (index) => {
    if (index < aturCaraMajlis.length - 1) {
      const updatedItems = [...aturCaraMajlis];
      const temp = updatedItems[index];
      updatedItems[index] = updatedItems[index + 1];
      updatedItems[index + 1] = temp;
      setAturCaraMajlis(updatedItems);
    }
  };

  const fontP = (selectedfontP || Components.fontP);

  return (
    <div className="min-h-screen relative flex items-center justify-center" style={heroStyle}>
      <div className="text-center z-10 my-20">
        <div className={Components.tStyle}>
          <h1 className={`text-l mb-8 uppercase font-bold ${fontP}`}>Atur Cara Majlis</h1>
          {isCreating ? (
            aturCaraMajlis.map((item, index) => (
              <div>
                <div key={index} className="my-4">
                  <input
                    type="text"
                    value={item.title}
                    onChange={(e) => {
                      handleCreateItem(index, e.target.value, item.time);
                    }}
                    className='px-4 py-2 border text-gray-600 md:w-auto w-full'
                  />
                  <input
                    type="time"
                    value={item.time}
                    onChange={(e) => {
                      handleCreateItem(index, item.title, e.target.value);
                    }}
                    className='px-4 py-2 border text-gray-600'
                  />
                  <button
                    onClick={() => handleDeleteItem(index)}
                    className="ml-5 bold text-white px-4 py-2 bg-red-600"
                  >
                    X
                  </button>
                  <button
                    onClick={() => handleMoveItemUp(index)}
                    className="ml-2 bold text-white px-4 py-2 bg-blue-600"
                  >
                    ↑
                  </button>
                  <button
                    onClick={() => handleMoveItemDown(index)}
                    className="ml-2 bold text-white px-4 py-2 bg-blue-600"
                  >
                    ↓
                  </button>
                </div>
              </div>
            ))
          ) : (
            aturCaraMajlis.map((item, index) => (
              <div key={index}  className={`my-4 ${fontP}`}>
                <p className="text-l">{item.title}:</p>
                <p className="text-l">{item.displaytime}</p>
              </div>
            ))
          )}
          <div className='mb-5'>
            {isCreating && (
              <button
                onClick={handleAddItem}
                className="text-xl text-green-500 mt-4 border border-green-500 rounded-full px-3 py-1"
              >
                +
              </button>
            )}
          </div>
          <div>
            {isCreating && (
              <button onClick={() => setIsCreating(!isCreating)} className="text-white px-4 py-2 bg-green-500 hover:bg-green-600 rounded-lg">
                Simpan
              </button>
            )}

            {!isCreating && (
              <button onClick={() => setIsCreating(!isCreating)} className="text-sm text-blue-500">
                Ubah Atur Cara Majlis
              </button>
            )}
          </div>
        </div>
      </div>
      <img
        src={Components.ft}
        
        className={Components.ftStyle}
      />
      <img
        src={Components.fb}
        
        className={Components.fbStyle}
      />
    </div>
  );
}

export default CreateTentative;
