import React from "react";

const RatingGraph = ({ ratings }) => {

  // Convert ratings object to an array of objects and sort it by rating in descending order
  const sortedRatings = Object.entries(ratings).sort(
    (a, b) => parseInt(b[0]) - parseInt(a[0])
  );

  return (
    <div className="container mx-auto">
      <div className="flex flex-col items-start justify-center space-y-4">
        {sortedRatings.map(([rating, count]) => (
          <div key={rating} className="flex items-center w-full">
            <span className="w-12 text-lg font-bold">{rating}</span>
            <div className="w-full bg-red-100 h-6 rounded-lg relative">
              <div
                className="absolute top-0 bottom-0 left-0 bg-red-400"
                style={{ width: `${(count / 1000) * 100}%` }}
              ></div>
            </div>
            <span className="ml-2 text-gray-700">{count}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default RatingGraph;
